// Libs
import React, { useRef } from 'react'
import { Form } from '@unform/web'
import { FaArrowLeft } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

// Validation
import { forgotSchema } from '../../validation'

// Redux
import { forgotPasswordRequest } from '../../store/modules'

// Components
import { Input, Button } from '../../components'

// Styles
import {
  Container,
  AnimatedContainer,
  Wrapper,
  Logo,
  StyledLink,
  Group
} from './styles'

// Svgs
import logo from '../../assets/images/logo/comites.svg'

export const Forgot = () => {
  // Hooks
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.auth)

  // Submit form Request
  const handleSubmit = async data => {
    try {
      formRef.current.setErrors({})

      await forgotSchema.validate(data, {
        abortEarly: false
      })

      const { email } = data

      dispatch(forgotPasswordRequest({ email }))
    } catch (err) {
      const validationErrors = {}
      if (err) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message
        })
        formRef.current.setErrors(validationErrors)
      }
    }
  }
  // Jsx
  return (
    <Container>
      <AnimatedContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <Logo>
              <img src={logo} alt="Comites" />
            </Logo>
            <h1>Recuperar senha</h1>
          </Wrapper>
          <Group>
            <Input
              name="email"
              type="text"
              aria-label="email"
              placeholder="Digite seu E-mail..."
              icon="FaEnvelope"
            />
          </Group>
          <Button loading={loading} type="submit">
            Recuperar
          </Button>
          <StyledLink to="/">
            <FaArrowLeft /> Voltar
          </StyledLink>
        </Form>
      </AnimatedContainer>
    </Container>
  )
}

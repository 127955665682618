// Libs
import React, { useRef, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from '@unform/web'

// Components
import { Modal, Input, Button } from '../../../components'

// Redux
import {
  changePasswordRequest
} from '../../../store/modules/user/actions'

// Styles
import { Container, Content } from './styles'

// Responsive
import { Row, Column } from '../../../styles'

// Validations
import { resetPasswordSchema } from '../../../validation'

export const ChangePasswordModal = ({ modalRef }) => {
  // Hooks
  const formRef = useRef(null)

  // Redux
  const dispatch = useDispatch()
  const { profile, loading, validationsErrors } = useSelector(
    state => state.user
  )

  // Submit form Request
  const handleSubmit = useCallback(
    async submitData => {
      try {
        formRef.current.setErrors({})

        await resetPasswordSchema.validate(submitData, {
          abortEarly: false
        })

        const { oldPassword, password, confirmPassword } = submitData

        dispatch(
          changePasswordRequest({
            id: profile.id,
            oldPassword,
            password,
            confirmPassword
          })
        )
        !validationsErrors && formRef.current.reset()
      } catch (err) {
        const validationErrors = {}
        if (err) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    },
    [dispatch, profile, validationsErrors]
  )
  const FormMemo = useMemo(() => {
    return (
      <Content>
        <h1>Trocar a senha</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row>
            <Column xs="12" sm="5" md="5" lg="10">
              <Input
                name="oldPassword"
                type="password"
                aria-label="password"
                placeholder="Sua senha atual..."
                autoComplete="off"
                icon="FaLock"
                reveal
              />
            </Column>
            <Column xs="12" sm="5" md="5" lg="10">
              <Input
                name="password"
                type="password"
                aria-label="password"
                placeholder="Sua nova senha..."
                autoComplete="off"
                icon="FaLock"
                reveal
              />
            </Column>
            <Column xs="12" sm="5" md="5" lg="10">
              <Input
                name="confirmPassword"
                type="password"
                aria-label="confirmPassword"
                placeholder="Confirme sua nova senha..."
                autoComplete="off"
                icon="FaLock"
                reveal
              />
            </Column>
            <Column xs="12" sm="5" md="5" lg="6" className="padding-top">
              {validationsErrors &&
                validationsErrors.map((e, i) => <p key={i}>{e}</p>)}
            </Column>
            <Column xs="12" sm="5" md="5" lg="10">
              <Button loading={loading} type="submit">
                Atualizar
              </Button>
            </Column>
          </Row>
        </Form>
      </Content>
    )
  }, [loading, validationsErrors, handleSubmit, formRef])

  return (
    <Modal ref={modalRef}>
      <Container>{FormMemo}</Container>
    </Modal>
  )
}

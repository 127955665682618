// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

// Api
import api from '../../../api'

// Types
import { BANNER_LIST_R } from './types'

// Actions
import { bannerListS, bannerListE } from './actions'

// Utils
import { error } from '../../../utils'

export function* listBanners() {
  try {
    const res = yield call(api.banners.list)

    const {
      data: {
        data,
        status: { code }
      }
    } = res
    if ([200].includes(code))
      return yield put(
        bannerListS({
          authenticate: data[0].authenticate,
          courses: data[0].courses,
          certificate: data[0].certificate
        })
      )
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response

      if ([400].includes(code)) {
        toast.error(`Erro ao buscar os Banners. ${message}`, error)
        return yield put(bannerListE())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao buscar os Banners. tente novamente mais tarde!',
          error
        )
        return yield put(bannerListE())
      }
    }
  }
}

export default all([takeLatest(BANNER_LIST_R, listBanners)])

import React, { forwardRef } from 'react'
import Vimeo from 'react-player'

export const Player = forwardRef(
  ({ player, provider, onTimeUpdate, onEnd, ...rest }, ref) => {
    return (
      <>
        {player === 'vimeo' || player === 'youtube' || player === 'sound' ? (
          <Vimeo
            className="vimeo-video"
            onEnded={onEnd}
            onProgress={onTimeUpdate}
            url={provider}
            ref={ref}
            {...rest}
          />
        ) : null}
      </>
    )
  }
)

// Libs
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FaArrowLeft } from 'react-icons/fa'

// Redux
import { verifyAccountRequest } from '../../store/modules'

// Styles
import {
  Container,
  AnimatedContainer,
  Wrapper,
  Logo,
  StyledLink
} from './styles'

// Svgs
import logo from '../../assets/images/logo/comites.svg'

export const AccountVerify = () => {
  // Hooks
  const { id, token_id, verify_token } = useParams()

  // Redux
  const dispatch = useDispatch()
  const { loading, account } = useSelector(state => state.auth)

  useEffect(() => {
    if (id && token_id && verify_token)
      dispatch(verifyAccountRequest({ id, token_id, verify_token }))
  }, [id, token_id, verify_token, dispatch])

  // Jsx
  return (
    <Container>
      <AnimatedContainer>
        <Wrapper>
          <Logo>
            <img src={logo} alt="Comites" />
          </Logo>
          {!loading ? (
            account && account.confirmed ? (
              <h1>Sua conta foi verificada com sucesso!</h1>
            ) : (
              <h1>No momento nao foi possivel verificar a conta!</h1>
            )
          ) : null}
          <StyledLink to="/dashboard">
            <FaArrowLeft /> Voltar
          </StyledLink>
        </Wrapper>
      </AnimatedContainer>
    </Container>
  )
}

import { combineReducers } from 'redux'

import { auth } from './auth/reducers'
import { user } from './user/reducers'
import { courses } from './courses/reducers'
import { player } from './player/reducers'
import { banners } from './banners/reducers'
import { utils } from './utils/reducers'

export default combineReducers({ auth, user, courses, player, banners, utils })

// Libs
import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { ModalProvider } from 'styled-react-modal'

// Styles Globals
import GlobalStyle from './styles/global'
import 'react-toastify/dist/ReactToastify.css'
import 'sweetalert2/src/sweetalert2.scss'
import 'react-circular-progressbar/dist/styles.css'

// Routes
import { Routes } from './routes'

export const ProvidersCustom = () => {
  // Redux
  const { banners: { certificate } } = useSelector(state => state.banners)

  return (
    <ThemeProvider theme={{}}>
      <ModalProvider>
        <Routes />
      </ModalProvider>
      <GlobalStyle banner={certificate && certificate} />
      <ToastContainer />
    </ThemeProvider>
  )
}

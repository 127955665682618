// Libs
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

// Redux
import { bannerListR } from '../../../store/modules'

// Components
import { Header } from '../../../components'

// Styles
import { Container } from './styles'

export const DefaultLayout = ({ children }) => {
  // Redux
  const dispatch = useDispatch()
  const { banners: { courses } } = useSelector(state => state.banners)

  useEffect(() => {
    if (!courses) dispatch(bannerListR())
  }, [courses, dispatch])

  return (
    <Container banner={courses && courses}>
      <Header />
      <main>{children}</main>
    </Container>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
}

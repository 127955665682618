// Get profile
export const GET_PROFILE_R = '@user/GET_PROFILE_R'
export const GET_PROFILE_S = '@user/GET_PROFILE_S'
export const GET_PROFILE_F = '@user/GET_PROFILE_F'

// Update user
export const UPDATE_USER_R = '@user/UPDATE_USER_R'
export const UPDATE_USER_S = '@user/UPDATE_USER_S'
export const UPDATE_USER_F = '@user/UPDATE_USER_F'
export const UPDATE_USER_V = '@user/UPDATE_USER_V'

// Change Password
export const CHANGE_PASSWORD_R = '@user/CHANGE_PASSWORD_R'
export const CHANGE_PASSWORD_S = '@user/CHANGE_PASSWORD_S'
export const CHANGE_PASSWORD_F = '@user/CHANGE_PASSWORD_F'
export const CHANGE_PASSWORD_V = '@user/CHANGE_PASSWORD_V'

// Certificate
export const CERTIFICATE_R = '@user/CERTIFICATE_R'
export const CERTIFICATE_S = '@user/CERTIFICATE_S'
export const CERTIFICATE_F = '@user/CERTIFICATE_F'
export const CERTIFICATE_V = '@user/CERTIFICATE_V'

// Libs
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from '@unform/web'

// Redux
import {
  signInRequest,
  getCoursesAuthRequest,
  sendVerifyAccountRequest
} from '../../store/modules'

// Components
import { Modal, Input, Button } from '../../components'

// Styles
import {
  Container,
  ContainerCourse,
  AnimatedContainer,
  Wrapper,
  Logo,
  Group,
  StyledLink,
  WrapperText,
  ButtonCourses,
  LinksContent,
  Course
} from './styles'

// Svgs
import logo from '../../assets/images/logo/comites.svg'

// Validations
import { authenticationSchema } from '../../validation'

export const SignIn = () => {
  // Hooks
  const [password, setPassword] = useState('')
  const [buttonDisable, setButtonDisable] = useState(true)

  // refs
  const formRef = useRef(null)
  const modalRef = useRef(null)

  // Redux
  const dispatch = useDispatch()
  const { loading, account } = useSelector(state => state.auth)
  const { coursesList } = useSelector(state => state.courses)

  useEffect(() => {
    if (!coursesList) dispatch(getCoursesAuthRequest())
  }, [coursesList, dispatch])

  // Button Disable with fields empty
  useEffect(() => {
    if (password.length > 3) setButtonDisable(false)
    else setButtonDisable(true)
  }, [password])

  // UseEffect Input Focus on load
  useEffect(() => {
    formRef.current.getFieldRef('email').focus()
    if (account && account.email) {
      formRef.current.setFieldValue('email', account.email)
    }
  }, [account])

  const handleModal = useCallback(() => {
    if (modalRef) {
      return modalRef.current.toggleModal()
    }
  }, [modalRef])

  const handleAccountVerify = () => {
    if (account && account.email && account.user_id)
      dispatch(
        sendVerifyAccountRequest({
          user_id: account.user_id,
          email: account.email
        })
      )
  }

  // Submit form Request
  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({})

        await authenticationSchema.signIn.validate(data, {
          abortEarly: false
        })

        const { email, password } = data

        dispatch(signInRequest({ email, password }))
      } catch (err) {
        const validationErrors = {}
        if (err) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    },
    [dispatch]
  )

  // Jsx
  return (
    <>
      <Container>
        <AnimatedContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Wrapper>
              <Logo>
                <img src={logo} alt="Rocketseat" />
              </Logo>
              <h1>
                Conecte-se na sua <strong>conta.</strong>
              </h1>
            </Wrapper>
            <Group>
              <Input
                name="email"
                type="text"
                aria-label="email"
                placeholder="E-mail"
                icon="FaEnvelope"
              />
              <Input
                name="password"
                type="password"
                aria-label="password"
                placeholder="Senha"
                autoComplete="off"
                icon="FaLock"
                reveal
                onChange={e => setPassword(e.target.value)}
              />
            </Group>
            <LinksContent>
              <StyledLink to="/forgot">Esqueci minha senha</StyledLink>
              {account && account.verified ? (
                <p onClick={handleAccountVerify}>Re-Enviar Verificação</p>
              ) : null}
            </LinksContent>
            <Button type="submit" loading={loading} disabled={buttonDisable}>
              Entrar
            </Button>
            <WrapperText>
              Não tem uma conta?{' '}
              <StyledLink to="/signup">Registre-se</StyledLink>
            </WrapperText>
            <hr />
            <ButtonCourses type="button" onClick={handleModal}>
              Ver Cursos Disponíveis
            </ButtonCourses>
          </Form>
        </AnimatedContainer>
      </Container>
      <Modal ref={modalRef}>
        <ContainerCourse>
          {coursesList &&
            coursesList.map((e, i) =>
              e.is_active ? (
                <Course key={i}>
                  <img src={e.thumbnail} alt={e.title} />
                  <span>{e.title}</span>
                  <p>{e.description}</p>
                </Course>
              ) : null
            )}
        </ContainerCourse>
      </Modal>
    </>
  )
}
export default SignIn

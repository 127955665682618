import { all } from 'redux-saga/effects'

import auth from './auth/sagas'
import user from './user/sagas'
import courses from './courses/sagas'
import player from './player/sagas'
import banners from './banners/sagas'
import utils from './utils/sagas'

export default function* rootSaga() {
  return yield all([auth, user, courses, player, banners, utils])
}

import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 72px;
  padding: 0px 30px;
  background: #12121496;
  top: 0px;
  z-index: 1000;
  transition: all 0.5s ease-in-out 0s;
  box-shadow: rgb(18, 18, 20) 0 0.1rem 2rem;
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1366px;
  display: flex;
  justify-content: space-between;
  color: rgb(255, 255, 255);
  padding: 12px 0px;
`

export const BackToPreviousPage = styled.div`
  margin-left: 56px;

  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-right: 10px;
    margin-left: 0px;
  }

  span {
    font-size: 15px;
    margin-left: 8px;
    font-weight: bold;
    color: var(--color-white);
  }
`

export const LeftContent = styled.div`
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 80%;
    height: 80%;
    border-radius: inherit;
    object-fit: contain;
  }
  button {
    border: none;
    background: none;
  }
`
export const RightContent = styled.div`
  display: flex;
  align-items: center;
`
export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-right: 14px;
`
export const ButtonIcon = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background: transparent;
  border: none;
  border-radius: 5px;
  transition: background 0.2s ease 0s;

  ${props =>
    props.isOpen
      ? `
  ::before {
    content: "";
    position: absolute;
    top: calc(100% + 17px);
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 7.5px 8px;
    border-color: transparent transparent rgb(41, 41, 46);
    }
  `
      : ''}

  :hover {
    background: rgb(20, 19, 22);

    svg {
      fill: rgb(255, 255, 255);
    }
  }

  svg {
    transition: fill 0.2s ease 0s;
    color: rgb(168, 168, 179);
  }
`
export const DrawerContainer = styled.div`
  position: absolute;
  right: 0px;
  top: calc(100% + 25px);
  width: 448px;
  box-shadow: rgba(0, 0, 0, 0.125) 0px 2px 5px;

  ${props => (props.isOpen ? 'display: block;' : 'display: none;')}

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(41, 41, 46);
    height: 50px;
    padding: 0px 20px;
    border-radius: 5px 5px 0px 0px;

    span {
      font-size: 16px;
      font-weight: bold;
      color: rgb(225, 225, 230);
    }
  }
`
export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(32, 32, 36);
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  font-size: 14px;
  color: rgb(168, 168, 179);
`

export const ProfileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
`
export const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 16px;
    font-weight: bold;
    color: rgb(225, 225, 230);
    transition: color 0.2s ease 0s;
  }

  @media screen and (max-width: 320px) {
    span { 
      display:none;
    }
  }
`

export const Profile = styled.div`
  width: 52px;
  height: 52px;
  border: 2px solid #0e9a52;
  border-radius: 50%;
  margin-left: 12px;
  padding: 3px;
  background: #0e9a52;
  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: contain;
  }
`
export const Dropdown = styled.div`
  position: absolute;
  right: 0px;
  top: calc(100% + 24px);
  width: 256px;
  padding: 0px;
  background: rgb(32, 32, 36);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.44) 0px 5px 20px;
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;

  ${props =>
    props.isOpen
      ? 'opacity: 1; visibility: visible;'
      : 'opacity: 0; visibility: hidden;'}

  ::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 19px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent rgb(32, 32, 36);
  }
`

export const DropdownLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: rgb(225, 225, 230);
  padding: 12px 24px;
  transition: background 0.2s ease 0s;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 100%;

  &:first-child {
    padding-top: 16px;
  }

  :hover {
    background: rgba(0, 0, 0, 0.125);
  }
`

export const DropdownButton = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: rgb(225, 225, 230);
  padding: 12px 24px;
  transition: background 0.2s ease 0s;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 100%;

  &:first-child {
    padding-top: 16px;
  }

  :hover {
    background: rgba(0, 0, 0, 0.125);
  }
`

export const BackgroundTransition = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 9;
  background-color: rgb(18, 18, 20);
  transition: all 180ms ease-in-out 0s;
  opacity: ${props => (props.isOpen ? '0.92' : '0')};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
`

import React, { useRef, useEffect, useCallback } from 'react'

// Libs
import { Form } from '@unform/web'
import { FaArrowLeft } from 'react-icons/fa'

// Redux
import { getCepRequest, signUpRequest } from '../../store/modules'

// Validation
import { authenticationSchema } from '../../validation'

// Components
import { Input, Button } from '../../components'

// Styles
import {
  Container,
  AnimatedContainer,
  Wrapper,
  Logo,
  Group,
  StyledLink
} from './styles'

// Svgs
import logo from '../../assets/images/logo/comites.svg'
import { useDispatch, useSelector } from 'react-redux'

export const SignUp = () => {
  // Hooks
  const formRef = useRef(null)

  // Redux
  const dispatch = useDispatch()
  const { cep } = useSelector(state => state.utils)
  const { loading } = useSelector(state => state.auth)

  // UseEffect Input Focus on load
  useEffect(() => {
    const focusNameInput = () => {
      const nameInput = formRef.current.getFieldRef('name')
      nameInput.focus()
    }
    focusNameInput()
  }, [])

  const setCep = useCallback(
    value => {
      value.length >= 8 && dispatch(getCepRequest({ cep: value }))
    },
    [dispatch]
  )

  useEffect(() => {
    const setInfos = () => {
      if (cep) {
        formRef.current.setData({
          address: cep.logradouro,
          city: cep.localidade,
          state: cep.uf,
          country: 'Brasil'
        })
      }
    }
    return setInfos()
  }, [cep])

  // Submit form Request
  const handleSubmit = async data => {
    try {
      formRef.current.setErrors({})

      await authenticationSchema.signUp.validate(data, {
        abortEarly: false
      })

      const {
        name,
        email,
        cpf: cleanCpf,
        cep,
        password,
        city,
        state,
        address,
        country
      } = data
      const cpf = cleanCpf.replace('-', '').replace('.', '').replace('.', '')
      dispatch(
        signUpRequest({
          name,
          email,
          cpf,
          cep,
          password,
          address,
          city,
          state,
          country
        })
      )
    } catch (err) {
      const validationErrors = {}
      if (err) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message
        })
        formRef.current.setErrors(validationErrors)
      }
    }
  }
  // Jsx
  return (
    <Container>
      <AnimatedContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <Logo>
              <img src={logo} alt="Comites" />
            </Logo>
            <h1>
              Crie sua <strong>conta.</strong>
            </h1>
          </Wrapper>
          <Group>
            <Input
              name="name"
              type="text"
              aria-label="name"
              placeholder="Seu Nome..."
              icon="FaUser"
            />
            <Input
              name="email"
              type="text"
              aria-label="email"
              placeholder="Seu E-mail..."
              icon="FaEnvelope"
            />
            <Input
              name="cpf"
              type="text"
              aria-label="cpf"
              placeholder="Seu Cpf..."
              icon="FaIdCard"
              autoComplete="off"
              unform={false}
              mask="999.999.999-99"
            />
            <Input
              name="cep"
              type="text"
              aria-label="cep"
              placeholder="Seu Cep..."
              icon="FaGlobeAmericas"
              autoComplete="off"
              onChange={e => setCep(e.target.value)}
            />
            <Input
              name="address"
              type="text"
              aria-label="endereço"
              placeholder="Seu Endereço..."
              icon="FaRegAddressBook"
              autoComplete="off"
            />

            <Input
              name="city"
              type="text"
              aria-label="Cidade"
              placeholder="Sua Cidade..."
              icon="FaCity"
              autoComplete="off"
            />
            <Input
              name="state"
              type="text"
              aria-label="estado"
              placeholder="Seu Estado..."
              icon="FaGlobe"
              autoComplete="off"
            />
            <Input
              name="country"
              type="text"
              aria-label="pais"
              placeholder="Seu País..."
              icon="FaMapMarked"
              autoComplete="off"
            />
            <Input
              name="password"
              type="password"
              aria-label="password"
              placeholder="Sua Senha..."
              autoComplete="off"
              icon="FaLock"
              reveal
            />
            <Input
              name="passwordConfirm"
              type="password"
              aria-label="passwordConfirm"
              placeholder="Confirme sua Senha..."
              autoComplete="off"
              icon="FaLock"
              reveal
            />
          </Group>
          <Button loading={loading} type="submit">
            Cadastrar
          </Button>
          <StyledLink to="/">
            <FaArrowLeft /> Voltar para login
          </StyledLink>
        </Form>
      </AnimatedContainer>
    </Container>
  )
}

// Libs
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

// Styles
import { Container, Content, Tables } from './styles'

// Helpers
import { formaters } from '../../helpers'

export const History = () => {
  // Redux
  const { profile } = useSelector(state => state.user)

  const TableMemo = useMemo(() => {
    if (profile && profile.history) {
      return (
        <section>
          <div>
            <h2>Historico</h2>
          </div>
          {profile.history.progresses.length > 0 && (
            <Tables>
              <table>
                <thead>
                  <tr>
                    <th>Curso</th>
                    <th>Aula</th>
                    <th>Completo</th>
                    <th>Visto</th>
                  </tr>
                </thead>
                <tbody>
                  {profile.history.progresses.map(e => (
                    <tr key={e.id}>
                      <td>
                        <div>{e.content.lesson.group && e.content.lesson.group.course.title}</div>
                      </td>
                      <td>
                        <div>{e.content.title}</div>
                      </td>
                      <td>
                        <div>{e.completed ? '100%' : '0%'}</div>
                      </td>
                      <td>
                        <div>{formaters.dateDistance(e.updated_at)}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tables>
          )}
        </section>
      )
    } else {
      return (
        <section>
          <div>
            <h2>Historico</h2>
          </div>
        </section>
      )
    }
  }, [profile])
  return (
    <Container>
      <Content>{TableMemo}</Content>
    </Container>
  )
}

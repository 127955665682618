import { css } from 'glamor'

export const error = {
  className: css({
    background: '#fff !important',
    color: 'rgb(221, 90, 70) !important'
  }),
  bodyClassName: css({
    fontSize: '16px',
    fontWeight: 'bold'
  }),
  progressClassName: css({
    background: 'rgb(221, 90, 70) !important'
  })
}

export const validation = {
  className: css({
    background: '#fff !important',
    color: 'rgb(221, 90, 70) !important'
  }),
  bodyClassName: css({
    fontSize: '16px',
    fontWeight: 'bold'
  }),
  progressClassName: css({
    background: 'rgb(221, 90, 70) !important'
  })
}

// Libs
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BounceLoader } from 'react-spinners'

// Redux
import { certificateRequest } from '../../store/modules'

// SubComponent
import { GenerateCertificated } from './Generate'

// Styles
import { LoaderContainer } from './styles'

export const Certificate = () => {
  // Hooks
  const { course_id } = useParams()

  // Redux
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.user)

  useEffect(() => {
    dispatch(certificateRequest({ course_id }))
  }, [course_id, dispatch])

  if (loading)
    return (
      <LoaderContainer>
        <BounceLoader color="#0E9A52" loading={loading} />
      </LoaderContainer>
    )

  return <GenerateCertificated />
}

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-image: ${({banner}) => banner ? `url(${banner})`: `url('https://wallsdesk.com/wp-content/uploads/2017/01/Liverpool-HD-Desktop.jpg')`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Wrapper = styled.div`
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1000px;
`;

export const Content = styled.div `
    flex: 1 1 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

import styled from 'styled-components'

export const Container = styled.div`
  label {
    display: block;
    width: 100%;
    min-height: 16px;
    font-size: 14px;
    color: rgb(135, 134, 139);
    margin-bottom: 8px;
  }

  span {
    display: inline-block;
    color: var(--color-error);
    font-size: 14px;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  flex: 1 1 0%;

  > input {
    width: 100%;
    height: 50px;
    font-size: 16px;
    outline: 0px;
    border-radius: 30px;
    padding: 15px 21px;
    transition: border 0.2s ease 0s;
    border: 2px solid rgb(40, 39, 44);

    background: ${({ background }) =>
      background ? background : 'var(--color-background)'};
    border-color: ${({ borderInputColor }) =>
      borderInputColor ? borderInputColor : 'var(--color-background)'};
    color: var(--color-gray);
    padding: ${({ icon }) => (icon ? '0px 1em 0px 2.65em' : '0px 1em 0px 1em')};

    ~ svg {
      fill: ${({ iconColor }) =>
        iconColor ? iconColor : 'rgba(55, 52, 53, 1)'};
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      transition: fill 0.2s ease 0s;
    }

    &:focus {
      border: 2px solid ${({ borderColor }) => borderColor};

      ~ svg {
        fill: ${({ borderColor }) => borderColor};
      }
    }

    ::placeholder {
      color: ${({ iconColor }) => (iconColor ? iconColor : 'var(--color-gray)')};
    }
    &:disabled {
      background: var(--color-purple-dark);
      color: rgba(255, 255, 255, 0.35);
      cursor: not-allowed;
    }
  }
  > textarea {
    width: 100%;
    font-size: 16px;
    outline: 0px;
    border-radius: 5px;
    padding: 15px 21px;
    transition: border 0.2s ease 0s;
    border: 2px solid rgb(40, 39, 44);

    background: ${({ background }) =>
      background ? background : 'var(--color-background)'};
    border-color: ${({ borderInputColor }) =>
      borderInputColor ? borderInputColor : 'var(--color-background)'};

    color: var(--color-white);
    padding: ${({ icon }) =>
      icon ? '10px 1em 0px 2.65em' : '10px 1em 0px 1em'};
    height: 100px;
    max-height: 300px;
    resize: vertical;
    &:focus {
      border: 2px solid ${({ borderColor }) => borderColor};

      ~ svg {
        fill: ${({ borderColor }) => borderColor};
      }
    }

    ::placeholder {
      color: ${({ iconColor }) => (iconColor ? iconColor : '#fff')};
    }
    &:disabled {
      background: var(--color-purple-dark);
      color: rgba(255, 255, 255, 0.35);
      cursor: not-allowed;
    }
  }
`

export const Reveal = styled.a`
  display: block;
  position: absolute;
  background: none;
  border: 0px;
  right: 16px;
  top: 55%;
  transform: translateY(-50%);
  transition: opacity 0.2s ease 0s;

  cursor: pointer;

  ${({ reveal }) => (reveal ? 'opacity: 0.7' : '')};

  > svg {
    fill: ${({ icon }) => (icon ? icon : 'var(--color-green-light)')};
    font-size: 18px;
  }
`

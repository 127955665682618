import { format, formatDistance, parseISO } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import pt from 'date-fns/locale/pt-BR'

export const formaters = {
  date: value =>
    format(
      zonedTimeToUtc(parseISO(value), 'America/Sao_Paulo'),
      'dd/MM/yyyy'
    ).toString(),
  dateDistance: value =>
    formatDistance(
      zonedTimeToUtc(Date.now(), 'America/Sao_Paulo'),
      zonedTimeToUtc(parseISO(value), 'America/Sao_Paulo'),
      {
        includeSeconds: true,
        addSuffix: true,
        locale: pt
      }
    ).toString(),
  cpf: value => value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

import { produce } from 'immer'

import {
  GET_PROFILE_R,
  GET_PROFILE_S,
  GET_PROFILE_F,
  UPDATE_USER_R,
  UPDATE_USER_S,
  UPDATE_USER_F,
  UPDATE_USER_V,
  CHANGE_PASSWORD_R,
  CHANGE_PASSWORD_S,
  CHANGE_PASSWORD_F,
  CHANGE_PASSWORD_V,
  CERTIFICATE_R,
  CERTIFICATE_S,
  CERTIFICATE_F,
  CERTIFICATE_V
} from './types'

const initialState = {
  profile: null,
  loading: false,
  certificate: null,
  userVerified: null,
  validationsErrors: null
}

export const user = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case GET_PROFILE_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case GET_PROFILE_S: {
        draft.loading = false
        draft.validationsErrors = null
        draft.profile = payload.profile
        draft.userVerified = payload.userVerified
        break
      }
      case GET_PROFILE_F: {
        draft.loading = false
        break
      }
      case UPDATE_USER_R: {
        draft.loading = true
        break
      }
      case UPDATE_USER_S: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case UPDATE_USER_F: {
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case UPDATE_USER_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case CHANGE_PASSWORD_R: {
        draft.loading = true
        break
      }
      case CHANGE_PASSWORD_S: {
        draft.loading = false
        break
      }
      case CHANGE_PASSWORD_F: {
        draft.loading = false
        break
      }
      case CHANGE_PASSWORD_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case CERTIFICATE_R: {
        draft.loading = true
        break
      }
      case CERTIFICATE_S: {
        draft.certificate = payload.certificate
        draft.loading = false
        break
      }
      case CERTIFICATE_F: {
        draft.loading = false
        break
      }
      case CERTIFICATE_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      default:
        return state
    }
  })
}

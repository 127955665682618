// Libs
import React, { useMemo } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
// Layouts
import { DefaultLayout, AuthLayout } from '../pages/_layouts'

export const RouteWrapper = ({ component: Component, isPrivate, ...rest }) => {
  const { token } = useSelector(state => state.auth)

  const LayoutMemo = useMemo(() => {
    const signed = token ? true : false

    if (!signed && isPrivate) {
      return <Redirect to="/" />
    }

    if (signed && !isPrivate) {
      return <Redirect to="/dashboard" />
    }

    const Layout = signed ? DefaultLayout : AuthLayout

    return (
      <Route
        {...rest}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  }, [token, rest, isPrivate])

  return LayoutMemo
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool
}

RouteWrapper.defaultProps = {
  isPrivate: false
}

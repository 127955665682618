import { produce } from 'immer'

import { BANNER_LIST_R, BANNER_LIST_S, BANNER_LIST_E } from './types'

const initialState = {
  banners: {
    courses: null,
    authenticate: null,
    certificate: null
  },
  loading: false
}

export const banners = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case BANNER_LIST_R: {
        draft.loading = true
        break
      }
      case BANNER_LIST_S: {
        draft.loading = false
        draft.banners = payload
        break
      }
      case BANNER_LIST_E: {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}

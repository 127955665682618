import styled from 'styled-components'

export const Container = styled.div`
  min-height: 100vh;
  color: var(--color-white);
  background-image: ${({ banner }) => banner ? `url(${banner})`: `url('https://wallpapercave.com/wp/wp1856658.jpg')`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

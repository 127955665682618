// Libs
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

// Redux
import { store, persistor } from './store'

// Services
import { history } from './services'

// Providers
import { ProvidersCustom } from './Providers'

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <BrowserRouter>
            <ProvidersCustom />
          </BrowserRouter>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App

import {
  SIGN_IN_R,
  SIGN_IN_S,
  SIGN_IN_F,
  SIGN_IN_V,
  SIGN_IN_A,
  SIGN_UP_R,
  SIGN_UP_S,
  SIGN_UP_F,
  SIGN_UP_V,
  SEND_VERIFY_ACCOUNT_R,
  SEND_VERIFY_ACCOUNT_S,
  SEND_VERIFY_ACCOUNT_F,
  VERIFY_ACCOUNT_R,
  VERIFY_ACCOUNT_S,
  VERIFY_ACCOUNT_F,
  FORGOT_PASSWORD_R,
  FORGOT_PASSWORD_S,
  FORGOT_PASSWORD_F,
  RESET_PASSWORD_R,
  RESET_PASSWORD_S,
  RESET_PASSWORD_F,
  RESET_PASSWORD_V,
  SIGN_OUT_R
} from './types'

// Sign in
export const signInRequest = payload => ({
  type: SIGN_IN_R,
  payload
})
export const signInSuccess = payload => ({
  type: SIGN_IN_S,
  payload
})
export const signInFailure = () => ({
  type: SIGN_IN_F
})
export const signInValidation = payload => ({
  type: SIGN_IN_V,
  payload
})
export const signInAccount = payload => ({
  type: SIGN_IN_A,
  payload
})

// Sign up
export const signUpRequest = payload => ({
  type: SIGN_UP_R,
  payload
})
export const signUpSuccess = payload => ({
  type: SIGN_UP_S,
  payload
})
export const signUpFailure = () => ({
  type: SIGN_UP_F
})
export const signUpValidation = payload => ({
  type: SIGN_UP_V,
  payload
})

// Account Verifycation
export const sendVerifyAccountRequest = payload => ({
  type: SEND_VERIFY_ACCOUNT_R,
  payload
})
export const sendVerifyAccountSuccess = () => ({
  type: SEND_VERIFY_ACCOUNT_S
})
export const sendVerifyAccountFailure = payload => ({
  type: SEND_VERIFY_ACCOUNT_F,
  payload
})
export const verifyAccountRequest = payload => ({
  type: VERIFY_ACCOUNT_R,
  payload
})
export const verifyAccountSuccess = payload => ({
  type: VERIFY_ACCOUNT_S,
  payload
})
export const verifyAccountFailure = payload => ({
  type: VERIFY_ACCOUNT_F,
  payload
})

// Forgot Password
export const forgotPasswordRequest = payload => ({
  type: FORGOT_PASSWORD_R,
  payload
})
export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_S
})
export const forgotPasswordFailure = payload => ({
  type: FORGOT_PASSWORD_F,
  payload
})
export const resetPasswordRequest = payload => ({
  type: RESET_PASSWORD_R,
  payload
})
export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_S
})
export const resetPasswordFailure = payload => ({
  type: RESET_PASSWORD_F,
  payload
})
export const resetPasswordValidation = payload => ({
  type: RESET_PASSWORD_V,
  payload
})

// Sign Out
export const signOut = payload => ({
  type: SIGN_OUT_R,
  payload
})

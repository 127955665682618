// Libs
import React, { useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FaArrowLeft } from 'react-icons/fa'
import { Form } from '@unform/web'

// Redux
import { resetPasswordRequest } from '../../store/modules'

// Components
import { Input, Button } from '../../components'

// Validation
import { resetSchema } from '../../validation'

// Styles
import {
  Container,
  AnimatedContainer,
  Wrapper,
  Logo,
  StyledLink,
  Group
} from './styles'

// Svgs
import logo from '../../assets/images/logo/comites.svg'

export const ResetPassword = () => {
  // Hooks
  const { id, token_id, token } = useParams()
  const formRef = useRef(null)

  // Redux
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.auth)

  // Submit form Request
  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({})

        await resetSchema.validate(data, {
          abortEarly: false
        })

        const { password } = data
        if (id && token_id && token)
          dispatch(resetPasswordRequest({ id, token_id, token, password }))
      } catch (err) {
        const validationErrors = {}
        if (err) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    },
    [id, token_id, token, dispatch]
  )

  // Jsx
  return (
    <Container>
      <AnimatedContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <Logo>
              <img src={logo} alt="Comites" />
            </Logo>
            <h1>Resetar a senha</h1>
          </Wrapper>
          <Group>
            <Input
              name="password"
              type="password"
              aria-label="password"
              placeholder="Sua Senha..."
              autoComplete="off"
              icon="FaLock"
              reveal
            />
            <Input
              name="passwordConfirm"
              type="password"
              aria-label="passwordConfirm"
              placeholder="Confirme sua Senha..."
              autoComplete="off"
              icon="FaLock"
              reveal
            />
          </Group>
          <Button loading={loading} type="submit">
            Resetar
          </Button>
          <StyledLink to="/">
            <FaArrowLeft /> Voltar
          </StyledLink>
        </Form>
      </AnimatedContainer>
    </Container>
  )
}

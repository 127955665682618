// Libs
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BounceLoader } from 'react-spinners'
// Redux
import { getCourseSlugRequest } from '../../store/modules'

// Styles
import { Container } from './styles'

// Subcomponents
import { Group } from './Group'

export const Courses = () => {
  const { slug } = useParams()

  // Redux
  const dispatch = useDispatch()
  const { course, loading } = useSelector(state => state.player)

  useEffect(() => {
    dispatch(getCourseSlugRequest({ slug }))
  }, [slug, dispatch])

  if (loading)
    return (
      <Container>
        <BounceLoader color="#0E9A52" loading={loading} />
      </Container>
    )

  switch (course.type) {
    case 'group':
      return <Group />
    default:
      return null
  }
}

import { produce } from 'immer'

import {
  GET_COURSES_R,
  GET_COURSES_S,
  GET_COURSES_F,
  GET_COURSES_AUTH_R,
  GET_COURSES_AUTH_S,
  GET_COURSES_AUTH_F
} from './types'

const initialState = {
  courses: null,
  coursesList: null,
  loading: false
}

export const courses = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case GET_COURSES_R: {
        draft.loading = true
        break
      }
      case GET_COURSES_S: {
        draft.loading = false
        draft.courses = payload.courses
        break
      }
      case GET_COURSES_F: {
        draft.loading = false
        break
      }
      case GET_COURSES_AUTH_R: {
        draft.loading = true
        break
      }
      case GET_COURSES_AUTH_S: {
        draft.loading = false
        draft.coursesList = payload.courses
        break
      }
      case GET_COURSES_AUTH_F: {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}

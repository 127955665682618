import React from 'react'

import Panel from '../Panel'
import Skeleton from '../Skeleton'

import { Container } from './styles'

export const LoadingCourses = () => {
  return (
    <Container>
      <Panel className="no-shadow">
        <span>
          <Skeleton className="img-skeleton" />
          <Skeleton className="row-skeleton" />
          <Skeleton className="row-skeleton" />
        </span>
      </Panel>
    </Container>
  )
}

import {
  GET_COURSES_R,
  GET_COURSES_S,
  GET_COURSES_F,
  GET_COURSES_AUTH_R,
  GET_COURSES_AUTH_S,
  GET_COURSES_AUTH_F
} from './types'

export const getCoursesRequest = () => ({
  type: GET_COURSES_R
})

export const getCoursesSuccess = payload => ({
  type: GET_COURSES_S,
  payload
})

export const getCoursesFailure = () => ({
  type: GET_COURSES_F
})

export const getCoursesAuthRequest = () => ({
  type: GET_COURSES_AUTH_R
})

export const getCoursesAuthSuccess = payload => ({
  type: GET_COURSES_AUTH_S,
  payload
})

export const getCoursesAuthFailure = () => ({
  type: GET_COURSES_AUTH_F
})

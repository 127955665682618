import styled from 'styled-components'

export const Container = styled.div`
  > div {
    border-radius: 5px 5px 0px 0px;
    .bg-skeleton {
      width: 100%;
      height: 70px;
      filter: brightness(96%);
    }
    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 115px;
      .img-skeleton {
        z-index: 1;
        width: 200px;
        height: 100px;
        margin: 36px 0 10px;
        border-radius: 5px;
      }
      .row-skeleton {
        height: 12px;
        &:nth-child(2) {
          width: 60%;
          margin-top: 25px;
        }
        &:nth-child(3) {
          width: 50%;
          margin-top: 10px;
        }
      }
    }
  }
`

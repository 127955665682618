import styled, { keyframes } from 'styled-components'
import Modal from 'styled-react-modal'

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`
export const Container = styled.div``

export const CustomModal = Modal.styled`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(32,32,36);
  padding: 30px 20px;
  border-radius: 5px;
  animation: ${appearFromRight} 0.5s ease;
  width: 100vh;

  @media only screen and (min-width: 1025px) {
    padding: 80px 60px;
  }

  .button-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3em;
    background: var(--color-green-light);
    border-radius: 5px;
    border: 0px;
    color: #fff;
    font-size: 16px;
    transition: background 0.2s ease 0s, color 0.2s ease 0s;
    :hover {
      background: var(--color-green-dark);
    }

    svg {
      fill: #fff;
    }
  }
`

// Libs
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  MdArrowBack,
  MdStorage,
  MdHistory,
  MdExitToApp,
  MdVpnKey
} from 'react-icons/md'

// Redux
import { getProfileRequest, signOut } from '../../store/modules'

// Components
import { ChangePasswordModal } from './ChangePassword'

// Syles
import {
  Container,
  Content,
  LeftContent,
  RightContent,
  BackToPreviousPage,
  ProfileContainer,
  ProfileContent,
  Profile,
  Dropdown,
  DropdownLink,
  DropdownButton,
  BackgroundTransition
} from './style'

// Svgs
import logo from '../../assets/images/logo/comites.svg'

export const Header = () => {
  // Hooks
  const [openProfileDrag, setOpenProfileDrag] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const modalRef = useRef(null)

  // Redux
  const dispatch = useDispatch()
  const { profile } = useSelector(state => state.user)

  const dragOpenProfile = dragOpen => setOpenProfileDrag(!dragOpen)

  useEffect(() => {
    if (!profile) dispatch(getProfileRequest())
  }, [profile, dispatch])

  const leaveApp = useCallback(() => {
    Swal.fire({
      icon: 'warning',
      title: 'Quer mesmo sair?',
      showCancelButton: true,
      confirmButtonColor: '#0E9A52',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      cancelButtonColor: '#121214'
    }).then(result => {
      if (result.isConfirmed) return dispatch(signOut())
    })
  }, [dispatch])

  const handleGoBack = useCallback(() => {
    if (history) history.goBack()
  }, [history])

  const handleModal = useCallback(() => {
    if (modalRef) {
      setOpenProfileDrag(!openProfileDrag)
      return modalRef.current.toggleModal()
    }
  }, [modalRef, openProfileDrag])

  return (
    <>
      <Container>
        <Content>
          <LeftContent>
            <Link to="/dashboard">
              <img src={logo} alt="Comites" />
            </Link>

            {location.pathname !== '/dashboard' && (
              <button type="button" onClick={handleGoBack}>
                <BackToPreviousPage>
                  <MdArrowBack color="#fff" size={18} />
                  <span>Voltar</span>
                </BackToPreviousPage>
              </button>
            )}
          </LeftContent>
          <RightContent>
            <ProfileContainer>
              <ProfileContent onClick={() => dragOpenProfile(openProfileDrag)}>
                <span>{profile && profile.name}</span>
                <Profile>
                  <img
                    src="https://cdn.onlinewebfonts.com/svg/img_266351.png"
                    alt={profile && profile.name}
                  />
                </Profile>
              </ProfileContent>
              <Dropdown isOpen={openProfileDrag}>
                <DropdownLink
                  to="/profile"
                  onClick={() => dragOpenProfile(openProfileDrag)}
                >
                  <span>Meus dados</span>
                  <MdStorage size={24} color="#fff" />
                </DropdownLink>
                <DropdownLink
                  to="/history"
                  onClick={() => dragOpenProfile(openProfileDrag)}
                >
                  <span>Histórico</span>
                  <MdHistory size={24} color="#fff" />
                </DropdownLink>
                <DropdownButton onClick={handleModal}>
                  <span>Trocar Senha</span>
                  <MdVpnKey size={24} color="#fff" />
                </DropdownButton>
                <DropdownButton onClick={leaveApp}>
                  <span>Sair</span>
                  <MdExitToApp size={24} color="#fff" />
                </DropdownButton>
              </Dropdown>
            </ProfileContainer>
          </RightContent>
        </Content>
      </Container>
      <BackgroundTransition isOpen={openProfileDrag} />
      <ChangePasswordModal modalRef={modalRef} />
    </>
  )
}

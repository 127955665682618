import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;

  background: #12121496;
  padding-top: 72px;
  min-height: 100vh;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1180px;
  padding: 50px 30px;

  section {
    background: rgb(32, 32, 36);
    border-radius: 5px;
    padding: 32px;
    div {
      h2 {
        font-size: 24px;
        margin: 0px;
      }
    }
    form {
      display: flex;
      flex-direction: column;

      .label-journey {
        display: block;
        width: 100%;
        min-height: 16px;
        font-size: 14px;
        color: rgb(135, 134, 139);
        margin-bottom: 8px;
      }
    }

    + section {
      margin-top: 24px;
    }
  }
`

export const Tables = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  overflow-x: auto;
  header {
    display: flex;
    align-items: end;
    max-width: 280px;
    width: 180px;
    h1 {
      font-size: 16px;
      color: rgb(255, 255, 255);
    }
    p {
      font-size: 16px;
      color: rgb(255, 255, 255);
      padding-left: 20px;
      margin-left: 30px;
      border-left: 1px solid rgb(255, 255, 255);
      word-break: break-word;
      max-width: 280px;
    }
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 10px;
    thead {
      tr {
        th {
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          text-align: left;
          padding-left: 15px;
          padding-bottom: 10px;
        }
      }
    }
    tbody {
      tr {
        background: #0e9a53;
        border-radius: 5px;
        transition: background 0.2s ease 0s;

        td {
          color: #fff;
          padding: 10px 15px;
          font-size: 16px;

          div {
            display: flex;
            align-items: center;
          }

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }
      }
    }
  }
`

import styled from 'styled-components'

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 1rem;
  &::after {
    content: '';
    clear: both;
    display: table;
  }
`

const getColumnWidth = span => {
  if (!span) return
  const width = (span / 12) * 100
  return `width: ${width}%`
}

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  padding: 0 10px 1rem;
  ${({ xs }) => (xs ? getColumnWidth(xs) : 'width: 100%')};
  @media only screen and (min-width: 768px) {
    ${({ sm }) => (sm ? getColumnWidth(sm) : 'width: 100%')};
  }
  @media only screen and (min-width: 992px) {
    ${({ md }) => (md ? getColumnWidth(md) : 'width: 100%')};
  }
  @media only screen and (min-width: 1200px) {
    ${({ lg }) => (lg ? getColumnWidth(lg) : 'width: 100%')};
  }
`

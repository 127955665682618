import { api } from './_httpClient'

const authenticate = async payload => api.post('/user/authenticate', payload)

const create = async payload => api.post('/user', payload)

const sendVerifyAccount = async payload =>
  api.post('/user/send_verify_account', payload)

const verifyAccount = async payload => api.post('/user/verify_account', payload)

const sendForgotPassword = async payload =>
  api.post('/user/forgot_password', payload)

const resetPassword = async payload => api.post('/user/reset_password', payload)

export {
  authenticate,
  create,
  sendVerifyAccount,
  verifyAccount,
  sendForgotPassword,
  resetPassword
}

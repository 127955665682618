import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10em;
`

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const AnimatedContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  animation: ${appearFromRight} 0.5s ease;
  max-width: 480px;
  background: #121214d1;
  border-radius: 5px;
  padding: 64px;
  text-align: center;

  > button {
    margin-top: 20px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 24px;
    line-height: 54px;
    color: var(--color-gray-light);
    margin-top: 24px;
    margin-bottom: 20px;
    margin-right: 20px;
    text-align: center;

    strong {
      font-size: 24px;
      line-height: 54px;
      color: var(--color-green-light);
      text-align: center;
    }
  }
`
export const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 10px;

  img {
    display: block;
    align-self: flex-start;
  }
`

export const StyledLink = styled(Link)`
  font-size: 16px;
  font-weight: 600;
  color: var(--color-green);
  margin: 34px 0px 24px;
  align-self: center;
  transition: opacity 0.2s ease 0s;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 16px;
    vertical-align: middle;
    display: inline-block;
  }
`

export const ButtonSubmit = styled.button`
  background: var(--color-green-light);
  border-radius: 5px;
  border: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  transition: background 0.2s ease 0s, color 0.2s ease 0s;
  text-transform: uppercase;

  &:disabled {
    background: var(--color-green-dark);
    color: rgba(255, 255, 255, 0.35);
    cursor: not-allowed;
  }
`

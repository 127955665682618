// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

// Api
import api from '../../../api'

// Types
import { GET_COURSES_R, GET_COURSES_AUTH_R } from './types'

// Actions
import {
  getCoursesSuccess,
  getCoursesFailure,
  getCoursesAuthSuccess,
  getCoursesAuthFailure
} from './actions'

// Utils
import { error } from '../../../utils'
export function* getCourses() {
  try {
    const res = yield call(api.courses.list)

    const {
      data: {
        data: coursesData,
        status: { code }
      }
    } = res

    if ([200].includes(code))
      return yield put(getCoursesSuccess({ courses: coursesData }))
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        toast.error(`Não foi possivel listar os cursos. ${message}`, error)
        return yield put(getCoursesFailure())
      }

      if ([500].includes(code)) {
        toast.error(
          'Não foi possivel listar os cursos. Atualize a pagina!',
          error
        )
        return yield put(getCoursesFailure())
      }
    } else {
      toast.error(
        'Não foi possivel listar os cursos. Atualize a pagina!',
        error
      )
      return yield put(getCoursesFailure())
    }
  }
}

export function* getCoursesAuth() {
  try {
    const res = yield call(api.courses.listAuth)

    const {
      data: {
        data: coursesData,
        status: { code }
      }
    } = res

    if ([200].includes(code))
      return yield put(getCoursesAuthSuccess({ courses: coursesData }))
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code }
        }
      } = err.response
      if ([400].includes(code)) {
        return yield put(getCoursesAuthFailure())
      }

      if ([500].includes(code)) {
        return yield put(getCoursesAuthFailure())
      }
    } else {
      return yield put(getCoursesAuthFailure())
    }
  }
}

export default all([
  takeLatest(GET_COURSES_R, getCourses),
  takeLatest(GET_COURSES_AUTH_R, getCoursesAuth)
])

import React, { useEffect, useRef, useState, useCallback } from 'react'

// Libs
import InputMask from 'react-input-mask'
import * as AllIcons from 'react-icons/all'
import PropTypes from 'prop-types'
import { isFunction } from 'lodash'
import { useField } from '@unform/core'

// Styles
import { Container, InputWrapper, Reveal } from './styles'

// Input
export const Input = ({
  name,
  label,
  icon,
  prepend,
  append,
  unform,
  reveal,
  background,
  borderColor,
  iconColor,
  borderInputColor,
  iconSize,
  multiline,
  ...rest
}) => {
  const inputRef = useRef(null)
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name)
  const [revealInput, setRevealInput] = useState(false)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  const props = {
    ...rest,
    ref: inputRef,
    id: fieldName,
    name,
    type: rest.type === 'password' && revealInput ? 'text' : rest.type,
    'aria-label': fieldName,
    defaultValue,
    onFocus: clearError,
    maskchar: ''
  }

  const InputIcon = icon ? AllIcons[icon] : null

  const ShowIcon = reveal
    ? revealInput
      ? AllIcons.HiEyeOff
      : AllIcons.HiEye
    : null

  const handleRevealInputShow = useCallback(
    revealInput => setRevealInput(!revealInput),
    [setRevealInput]
  )

  const renderAppend = () =>
    append && (
      <div className="append">{isFunction(append) ? append() : append}</div>
    )

  const renderInput = () => {
    if (prepend || append) {
      return (
        <div>
          {prepend && <input value={prepend} disabled {...props} />}
          {unform ? <input {...props} /> : <InputMask {...props} />}

          {renderAppend()}
        </div>
      )
    }

    return multiline ? (
      <textarea {...props} />
    ) : unform ? (
      <input {...props} />
    ) : (
      <InputMask {...props} />
    )
  }

  return (
    <Container>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <InputWrapper
        background={background}
        borderColor={borderColor}
        iconColor={iconColor}
        borderInputColor={borderInputColor}
        icon={icon}
      >
        {renderInput()}
        {InputIcon && <InputIcon size={iconSize} />}
        {reveal && (
          <Reveal
            icon={iconColor}
            reveal={revealInput}
            tabindex="-1"
            onClick={() => handleRevealInputShow(revealInput)}
          >
            {ShowIcon && <ShowIcon />}
          </Reveal>
        )}
      </InputWrapper>
      {error && <span>{error}</span>}
    </Container>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  prepend: PropTypes.string,
  append: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
  unform: PropTypes.bool,
  reveal: PropTypes.bool,
  icon: PropTypes.string,
  background: PropTypes.string,
  borderColor: PropTypes.string,
  iconColor: PropTypes.string,
  borderInputColor: PropTypes.string,
  iconSize: PropTypes.number,
  multiline: PropTypes.bool
}
Input.defaultProps = {
  prepend: '',
  append: '',
  label: null,
  icon: null,
  unform: true,
  reveal: false,
  background: '#fff',
  borderColor: '#0E9A52',
  iconColor: '',
  borderInputColor: '',
  iconSize: 18,
  multiline: false
}

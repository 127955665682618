import { GET_CEP_R, GET_CEP_S, GET_CEP_F } from './types'

// Cep
export const getCepRequest = payload => ({
  type: GET_CEP_R,
  payload
})
export const getCepSuccess = payload => ({
  type: GET_CEP_S,
  payload
})
export const getCepFailure = () => ({
  type: GET_CEP_F
})

import { BANNER_LIST_R, BANNER_LIST_S, BANNER_LIST_E } from './types'

// List
export const bannerListR = () => ({
  type: BANNER_LIST_R
})
export const bannerListS = payload => ({
  type: BANNER_LIST_S,
  payload
})
export const bannerListE = () => ({
  type: BANNER_LIST_E
})

import * as Yup from 'yup'

const authenticationSchema = {
  signIn: Yup.object().shape({
    email: Yup.string()
      .email('Insira um email válido')
      .required('O email é obrigatório'),
    password: Yup.string()
      .min(6, 'No mínimo, 6 caracteres')
      .required('A senha é obrigatória')
  }),
  signUp: Yup.object().shape({
    email: Yup.string()
      .email('Insira um email válido')
      .required('E-mail é obrigatório'),
    name: Yup.string()
      .matches(
        /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g,
        'Digite seu nome completo'
      )
      .required('Nome é obrigatório'),
    cpf: Yup.string().required('Cpf é obrigatório'),
    cep: Yup.string().required('Cep é obrigatório'),
    password: Yup.string()
      .min(6, 'No mínimo, 6 caracteres')
      .required('Senha é obrigatório'),
    passwordConfirm: Yup.string()
      .min(6, 'No mínimo, 6 caracteres')
      .required('Confirmação de senha é obrigatório')
      .oneOf([Yup.ref('password'), null], 'Senhas não conferem.')
  })
}

const forgotSchema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('E-mail é obrigatório')
})

const resetSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'No mínimo, 6 caracteres')
    .required('Senha é obrigatório'),
  passwordConfirm: Yup.string()
    .min(6, 'No mínimo, 6 caracteres')
    .required('Confirmação de senha é obrigatório')
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem.')
})
export { authenticationSchema, forgotSchema, resetSchema }

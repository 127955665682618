// Get course by slug
export const GET_COURSE_SLUG_R = '@player/GET_COURSE_SLUG_R'
export const GET_COURSE_SLUG_S = '@player/GET_COURSE_SLUG_S'
export const GET_COURSE_SLUG_F = '@player/GET_COURSE_SLUG_F'

// Progress
export const SET_CURRENT = '@player/SET_CURRENT'
export const CURRENT = '@player/CURRENT'
export const NEXT_LESSON = '@player/NEXT_LESSON'
export const HANDLE_PROGRESS_R = '@player/HANDLE_PROGRESS_R'
export const HANDLE_PROGRESS_S = '@player/HANDLE_PROGRESS_S'
export const HANDLE_PROGRESS_F= '@player/HANDLE_PROGRESS_F'

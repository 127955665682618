import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  form {
    div {
      justify-content: center;
    }
    .label-journey {
      display: block;
      width: 100%;
      min-height: 16px;
      font-size: 14px;
      color: rgb(135, 134, 139);
      margin-bottom: 8px;
    }
    .padding-top {
      padding-top: 1rem;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 1366px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px;
  h1 {
    color: var(--color-green);
  }
`

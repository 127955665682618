import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const AnimatedContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  animation: ${appearFromRight} 0.5s ease;

  form {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 480px;
    background: #121214d1;
    border-radius: 5px;
    padding: 64px;

    hr {
      background-color: #ffffff61;
      height: 1px;
      border: none;
      border-radius: 30px;
      margin-top: 35px;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: 480px;
`

export const Wrapper = styled.div`
  h1 {
    font-size: 24px;
    line-height: 54px;
    color: var(--color-gray-light);
    margin-bottom: 24px;
    margin-right: 20px;
    text-align: center;

    strong {
      font-size: 24px;
      line-height: 54px;
      color: var(--color-green-light);
      text-align: center;
    }
  }
`
export const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  img {
    display: block;
    align-self: flex-start;
  }
`

export const Group = styled.section`
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
`
export const LinksContent = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-green);
    margin: 8px 0px 24px;
    align-self: flex-start;
    transition: opacity 0.2s ease 0s;
    background: none;
    border: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`
export const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: var(--color-green);
  margin: 8px 0px 24px;
  align-self: flex-start;
  transition: opacity 0.2s ease 0s;

  &:hover {
    opacity: 0.8;
  }
`

export const WrapperText = styled.div`
  font-size: 14px;
  margin-top: 24px;
  color: var(--color-gray-dark);
  text-align: center;
`

export const ButtonCourses = styled.button`
  background: var(--color-green-light);
  border-radius: 50px;
  border: 0px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  transition: background 0.2s ease 0s, color 0.2s ease 0s;
  text-transform: uppercase;
  margin-top: 30px;
`

export const ContainerCourse = styled.div`
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    overflow-y: auto;
    max-height: 500px;  

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-corner {
    background: rgb(41, 41, 46);
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(77, 77, 87);
  }
  ::-webkit-scrollbar-track {
    background: rgb(242, 242, 255);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1,1fr);
  }
`

export const Course = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 30px 10px;
  border-radius: 5px 5px 0px 0px;
  transition: background 0.2s ease 0s;
  background: rgb(41, 41, 46);
  :hover {
    background: rgb(40, 40, 40);
  }

  img {
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
  }

  span {
    font-size: 24px;
    font-weight: bold;
    margin-top: 1em;
    color: rgb(225, 225, 230);
    white-space: nowrap;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    font-size: 16px;
    line-height: 2;
    color: rgb(168, 168, 179);
    text-align: center;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 320px) {
    span { 
      max-width: 180px;
    }
    p { 
      max-width: 180px;
    }
  }

  @media screen and (max-width: 375px) {
    span { 
      max-width: 200px;
    }
    p { 
      max-width:  200px;
    }
  }
  @media screen and (max-width: 768px) {
    span { 
    max-width: 250px;
    }
  }
`

import styled from 'styled-components'

export const Container = styled.aside`
  position: relative;
  width: 350px;
  margin-left: 30px;
  background: rgb(25, 25, 28);
  border-radius: 5px;
  overflow: hidden;
  display: initial;
`

export const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`
export const LessonContent = styled.div``

export const LessonList = styled.ul`
  margin: 30px;
  p {
    text-align: center;
    :first-child {
      margin-bottom: 20px;
      margin-top: 0;
    }
    margin-top: 20px;
  }
`
export const Lesson = styled.li`
  font-size: 14px;
  display: flex;
  align-items: center;

  position: relative;
  z-index: 5;

  a {
    font-size: 16px;
    color: ${({ watched }) =>
      watched ? 'rgb(4, 211, 97)' : 'rgb(168, 168, 179)'};
  }
  p {
    font-size: 12px;
    margin-top: 10px;
  }
  svg {
    position: relative;
    cursor: pointer;
    border: 0px;
    fill: rgb(60, 60, 66);
    border-radius: 50%;
    margin-right: 27px;
    flex-shrink: 0;
    z-index: 2;
    transition: box-shadow 0.2s ease 0s;
  }

  button {
    position: relative;
    cursor: pointer;
    width: 10px;
    height: 10px;
    border: 0px;
    background: ${({ watched }) =>
      watched ? 'rgb(4, 211, 97)' : 'rgb(60, 60, 66)'};
    border-radius: 50%;
    margin-right: 30px;
    flex-shrink: 0;
    z-index: 2;
    transition: box-shadow 0.2s ease 0s;

    ::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%);
      background: ${({ active }) =>
        active
          ? `
        background: rgb(32, 32, 36);
        border: 2px solid rgb(4, 211, 97);
        border-radius: 50%;
          `
          : ''};
    }
    ::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
      border-radius: 50%;

      ${({ watched }) => watched && 'background:rgb(4, 211, 97);'};
      ${({ active }) => active && 'background:rgb(225, 225, 230);'}
    }
  }

  :first-child {
    ::after {
      content: '';
      left: 4px;
      width: 2px;
      top: 50%;
      height: calc(100% + 5px);
      background: ${({ watched }) =>
        watched ? 'rgb(4, 211, 97)' : 'rgb(60, 60, 66)'};
      position: absolute;
    }
  }

  + li {
    margin-top: 10px;

    ${({ hideConnector }) =>
      hideConnector
        ? `
        margin-top: 30px;
        ::before,
        ::after {
          content: '';
          left: 4px;
          width: 2px;
          top: 50%;
          height: calc(100% + 5px);
          background: ${({ watched }) =>
            watched ? 'rgb(4, 211, 97)' : 'rgb(60, 60, 66)'};
          position: absolute;
        }

        ::before {
          display: none;
        }
        :last-child::after {
          display: none;
        }
        `
        : `
      ::before,
      ::after {
        content: '';
        left: 4px;
        width: 2px;
        top: 50%;
        height: calc(100% + 5px);
        background: ${({ watched }) =>
          watched ? 'rgb(4, 211, 97)' : 'rgb(60, 60, 66)'};
        position: absolute;
      }

      ::before {
        top: auto;
        bottom: 50%;
      }
      :last-child::after {
        display: none;
      }
    `}
  }
`
export const Wrapper = styled.div`
  flex: 1 1 0%;
  overflow-y: auto;
  padding-top: 0px;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-corner {
    background: rgb(41, 41, 46);
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(77, 77, 87);
  }
  ::-webkit-scrollbar-track {
    background: rgb(242, 242, 255);
  }
`

export const ProgressContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`

export const Progress = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  background: rgb(0, 0, 0);
  border-radius: 50%;
  flex-shrink: 0;

  span {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    font-size: 14px;
    font-weight: bold;
    color: rgb(225, 225, 230);
    p {
      border-top: 1px solid white;

      font-size: 12px;
    }
  }
`

export const LessonProgress = styled.svg`
  position: absolute;
  top: 0px;
  left: 0px;

  circle {
    stroke-width: 2.5;
    stroke-dasharray: 106, 106;
    stroke-dashoffset: ${({ progress }) => (progress === 100 ? 0 : progress)};
    stroke: rgb(4, 211, 97);
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke 0.3s ease 0s, stroke-dashoffset 0.45s ease 0s;
  }

  + svg {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -150%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease 0s;
  }
`

export const Material = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  padding: 20px;

  > div {
    a {
      font-size: 16px;
      color: rgb(168, 168, 179);
    }
    p {
      font-size: 12px;
      margin-top: 10px;
    }
  }

  svg {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 0px;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0;
    z-index: 2;
    transition: box-shadow 0.2s ease 0s;
  }
`

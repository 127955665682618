import {
  GET_PROFILE_R,
  GET_PROFILE_S,
  GET_PROFILE_F,
  UPDATE_USER_R,
  UPDATE_USER_S,
  UPDATE_USER_F,
  UPDATE_USER_V,
  CHANGE_PASSWORD_R,
  CHANGE_PASSWORD_S,
  CHANGE_PASSWORD_F,
  CHANGE_PASSWORD_V,
  CERTIFICATE_R,
  CERTIFICATE_S,
  CERTIFICATE_F,
  CERTIFICATE_V
} from './types'

// Get Profile
export const getProfileRequest = () => ({
  type: GET_PROFILE_R
})
export const getProfileSuccess = payload => ({
  type: GET_PROFILE_S,
  payload
})
export const getProfileFailure = () => ({
  type: GET_PROFILE_F
})

// Uodate User
export const updateUserRequest = payload => ({
  type: UPDATE_USER_R,
  payload
})
export const updateUserSuccess = payload => ({
  type: UPDATE_USER_S,
  payload
})
export const updateUserFailure = () => ({
  type: UPDATE_USER_F
})
export const updateUserValidation = payload => ({
  type: UPDATE_USER_V,
  payload
})
// Change Password
export const changePasswordRequest = payload => ({
  type: CHANGE_PASSWORD_R,
  payload
})
export const changePasswordSuccess = payload => ({
  type: CHANGE_PASSWORD_S,
  payload
})
export const changePasswordFailure = () => ({
  type: CHANGE_PASSWORD_F
})
export const changePasswordValidation = payload => ({
  type: CHANGE_PASSWORD_V,
  payload
})

// Certificate
export const certificateRequest = payload => ({
  type: CERTIFICATE_R,
  payload
})
export const certificateSuccess = payload => ({
  type: CERTIFICATE_S,
  payload
})
export const certificateFailure = () => ({
  type: CERTIFICATE_F
})
export const certificateValidation = payload => ({
  type: CERTIFICATE_V,
  payload
})

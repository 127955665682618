// List
const BANNER_LIST_R = '@banners/BANNER_LIST_R'
const BANNER_LIST_S = '@banners/BANNER_LIST_S'
const BANNER_LIST_E = '@banners/BANNER_LIST_E'


export {
  BANNER_LIST_R,
  BANNER_LIST_S,
  BANNER_LIST_E
}

// Libs
import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { MdPictureAsPdf } from 'react-icons/md'
import { useReactToPrint } from 'react-to-print'

// Styles
import {
  Container,
  AnimatedContainer,
  Wrapper,
  Logo,
  Content,
  Footer
} from '../styles'

// Svgs
import logo from '../../../assets/images/logo/comites.svg'

// Helpers
import { formaters } from '../../../helpers'

export const GenerateCertificated = () => {
  // Hooks
  const pdfRef = useRef()

  // Redux
  const { certificate } = useSelector(state => state.user)

  const handlePrint = useReactToPrint({
    documentTitle: certificate
      ? `${certificate.user.name}-certificate-course-${certificate.course.slug}`
      : 'certificate',
    content: () => pdfRef.current
  })

  const CertificateMemo = useMemo(() => {
    if (certificate) {
      if (certificate.course && certificate.user) {
        return (
          <>
            <Logo>
              <img src={logo} alt="Comites" />
            </Logo>
            <Content>
              <p>Este certificado é destinado a</p>
              <h1>{certificate.user.name}</h1>
              <p>
                Pela conclusão do Curso Online {certificate.course.title} <br />
                Constituído de{' '}
                {Number(certificate.lessons).toFixed(0) > 2
                  ? `${Number(certificate.lessons).toFixed(0)} aulas`
                  : `${Number(certificate.lessons).toFixed(0)} aula`}{' '}
                , com carga horaria total <br /> de{' '}
                {Number(certificate.hours / 60) < 60
                  ? `${Number(certificate.hours / 60).toFixed(0)} Minutos`
                  : `${Number(certificate.hours / 60 / 60).toFixed(0)} Horas`}
                .
              </p>
              <p>São Paulo, {formaters.date(certificate.created_at)}</p>
            </Content>
            <Footer>
              <p>
                Av.Higienópolis, 436 - São Paulo/Sp
                <br /> CEP: 01238-000 - Brasil
                <br /> Tel/Fax: (11) 3287-3517 | 3141-2890
              </p>
              <p>
                comites.sp@comites.org.br
                <br />
                www.COMITES.org.br
              </p>
            </Footer>
          </>
        )
      } else {
        return (
          <>
            <Logo>
              <img src={logo} alt="Comites" />
            </Logo>
            <Content>
              <h1>Não foi possivel achar o curso pelo id</h1>
            </Content>
          </>
        )
      }
    }
  }, [certificate])

  return certificate ? (<Container>
    <div className="print" ref={pdfRef}>
      <AnimatedContainer className="print_middle">
        <Wrapper  className="print_wrapper">{CertificateMemo}</Wrapper>
      </AnimatedContainer>
        <button className="print-omit" onClick={handlePrint}>
          <MdPictureAsPdf />
            Gerar Pdf
        </button>
    </div>
  </Container>) : null
}

// Libs
import { all, takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { toast } from 'react-toastify'

// Api
import api from '../../../api'

// Types
import {
  HANDLE_PROGRESS_R,
  GET_COURSE_SLUG_R,
  CURRENT,
  NEXT_LESSON
} from './types'

// Actions
import {
  getCourseSlugSuccess,
  getCourseSlugFailure,
  setCurrent,
  handleProgressS,
  handleProgressF
} from './actions'

// Services
import { history } from '../../../services'

export const calculateLessonProgress = ({ lessons }) => {
  const videosLessons = lessons.filter(
    lesson => lesson.type === 'video' || lesson.type === 'sound'
  )

  const completed = videosLessons.filter(
    lesson => lesson.content.progress[0] && lesson.content.progress[0].completed
  ).length

  if(videosLessons.length > 0 )
    return Number((completed / videosLessons.length) * 100).toFixed(0)
  
  return Number(0)
}

export function* getCourseSlug({ payload }) {
  try {
    const { slug } = payload
    const res = yield call(api.player.getCourse, slug)

    const {
      data: {
        data: courseData,
        status: { code }
      }
    } = res

    if ([200].includes(code)) {
      const progressLessons = yield call(calculateLessonProgress, {
        lessons: courseData.group.lessons
      })
      if (progressLessons > 0) {
        return yield put(
          getCourseSlugSuccess({
            course: courseData,
            progress: progressLessons
          })
        )
      } else {
        return yield put(
          getCourseSlugSuccess({ course: courseData, progress: 0 })
        )
      }
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code }
        }
      } = err.response
      if ([400].includes(code)) {
        return yield put(getCourseSlugFailure())
      }

      if ([500].includes(code)) {
        return yield put(getCourseSlugFailure())
      }
    } else {
      return yield put(getCourseSlugFailure())
    }
  }
}

export function groupNext({ data, current }) {
  const lessons = data.group.lessons.filter(
    e => e.type === 'video' || e.type === 'sound'
  )
  const currentIndex = lessons.findIndex(e => e.id === current.id)

  const nextLesson = lessons[currentIndex + 1]

  return {
    nextLesson
  }
}

export function* loadGroup({ payload }) {
  const { course, current } = payload
  const currentLesson =
    current ||
    course.current_lesson.content.slug ||
    course.group.lessons[0].content.slug

  const lessons = course.group.lessons.filter(
    lesson => lesson.type === 'video' || lesson.type === 'sound'
  )

  const currentInfo = lessons.find(
    lesson => lesson.content.slug === currentLesson
  )

  yield put(setCurrent({ nextLesson: currentInfo }))
}

export function* handleLessonProgress({ payload }) {
  try {
    const { time, percentage } = payload
    const { current, lessons } = yield select(state => state.player)

    const res = yield call(api.player.progress, {
      lesson_content_id: current.content.id,
      percentage,
      progress_time: time
    })

    const {
      data: {
        data: progressData,
        status: { code }
      }
    } = res

    if ([201, 200].includes(code)) {
      const { percentage, progress_time, completed } = progressData
      const updateLessons = lessons.map(e => {
        if (e.id === current.id) {
          return {
            ...e,
            content: {
              ...e.content,
              progress: [{ percentage, progress_time, completed }]
            }
          }
        } else {
          return e
        }
      })
      const progressLessons = yield call(calculateLessonProgress, {
        lessons: updateLessons
      })
      if (progressLessons) {
        return yield put(
          handleProgressS({ lessons: updateLessons, progress: progressLessons })
        )
      } else {
        return yield put(handleProgressS({ lessons: lessons, progress: 0 }))
      }
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code }
        }
      } = err.response
      if ([400].includes(code)) {
        return yield put(handleProgressF())
      }

      if ([500].includes(code)) {
        return yield put(handleProgressF())
      }
    } else {
      return yield put(handleProgressF())
    }
  }
}

export function* next() {
  const { course, current } = yield select(state => state.player)

  const { nextLesson } = yield call(groupNext, {
    data: course,
    current
  })
  if (nextLesson) {
    toast.dark(`Pulando para a proxima aula em 3 segundos...`, {
      autoClose: 3000
    })
    yield delay(3000)
    history.push(`/course/${course.slug}/${nextLesson.content.slug}`)
    return yield put(setCurrent({ nextLesson }))
  } else {
    toast.dark(`Última Aula do Curso Concluida...`, {
      autoClose: 2000
    })
    yield delay(2000)
    history.push(
      `/course/${course.slug}/${course.group.lessons[0].content.slug}`
    )
    return yield put(setCurrent({ nextLesson: course.group.lessons[0] }))
  }
}

export default all([
  takeLatest(HANDLE_PROGRESS_R, handleLessonProgress),
  takeLatest(GET_COURSE_SLUG_R, getCourseSlug),
  takeLatest(CURRENT, loadGroup),
  takeLatest(NEXT_LESSON, next)
])

// Libs
import axios from 'axios'
import { store, persistor } from '../store'
// Services
import { history } from '../services'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

api.interceptors.request.use(async config => {
  const {
    auth: { token }
  } = store.getState()

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`
    }
    return config
  } else {
    config.headers = {
      ...config.headers
    }
    return config
  }
})

api.interceptors.response.use(
  async response => {
    return response
  },
  err => {
    const {
      auth: { token }
    } = store.getState()

    if (
      token &&
      (err.response.status === 401 || err.response.data.statusCode === 401)
    ) {
      persistor.pause()
      persistor.flush().then(() => persistor.purge())
      history.go('/')
    }
    return Promise.reject(err)
  }
)

import styled from 'styled-components'

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--color-green-light);
  border-radius: 30px;
  border: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  transition: background 0.2s ease 0s, color 0.2s ease 0s;
  text-transform: uppercase;
  margin-top: 15px;
  &:disabled {
    background: var(--color-green-dark);
    color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
  }

  svg {
    margin-right: 0.5rem;
  }
`

import { produce } from 'immer'

import {
  SIGN_IN_R,
  SIGN_IN_S,
  SIGN_IN_F,
  SIGN_IN_V,
  SIGN_IN_A,
  SIGN_UP_R,
  SIGN_UP_S,
  SIGN_UP_F,
  SIGN_UP_V,
  SEND_VERIFY_ACCOUNT_R,
  SEND_VERIFY_ACCOUNT_S,
  SEND_VERIFY_ACCOUNT_F,
  VERIFY_ACCOUNT_R,
  VERIFY_ACCOUNT_S,
  VERIFY_ACCOUNT_F,
  FORGOT_PASSWORD_R,
  FORGOT_PASSWORD_S,
  FORGOT_PASSWORD_F,
  RESET_PASSWORD_R,
  RESET_PASSWORD_S,
  RESET_PASSWORD_F,
  RESET_PASSWORD_V,
  SIGN_OUT_R
} from './types'

const initialState = {
  token: null,
  name: null,
  loading: false,
  validationsErrors: null,
  account: {
    verified: false,
    confirmed: false,
    email: null,
    user_id: null
  }
}

export const auth = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case SIGN_IN_R: {
        draft.loading = true
        draft.validationsErrors = null
        draft.account = state.account
        break
      }
      case SIGN_IN_S: {
        draft.loading = false
        draft.validationsErrors = null
        draft.token = payload.token
        draft.account = state.account
        break
      }
      case SIGN_IN_F: {
        draft.loading = false
        draft.account = state.account
        break
      }
      case SIGN_IN_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case SIGN_IN_A: {
        draft.loading = false
        draft.account = payload
        break
      }
      case SIGN_UP_R: {
        draft.loading = true
        draft.validationsErrors = null
        draft.account = state.account
        break
      }
      case SIGN_UP_S: {
        draft.loading = false
        draft.validationsErrors = null
        draft.account = { ...state.account, email: payload.user.email }
        break
      }
      case SIGN_UP_F: {
        draft.loading = false
        draft.account = state.account
        break
      }
      case SIGN_UP_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case SEND_VERIFY_ACCOUNT_R: {
        draft.loading = true
        break
      }
      case SEND_VERIFY_ACCOUNT_S: {
        draft.loading = false
        draft.account = { ...state.account, verified: false, user_id: null }
        break
      }
      case SEND_VERIFY_ACCOUNT_F: {
        draft.loading = false
        break
      }
      case VERIFY_ACCOUNT_R: {
        draft.loading = true
        draft.account = { ...state.account, confirmed: false }
        break
      }
      case VERIFY_ACCOUNT_S: {
        draft.loading = false
        draft.account = {
          ...state.account,
          verified: false,
          user_id: null,
          confirmed: payload.confirmed
        }
        break
      }
      case VERIFY_ACCOUNT_F: {
        draft.loading = false
        draft.account = { ...state.account, confirmed: false }
        break
      }
      case FORGOT_PASSWORD_R: {
        draft.loading = true
        break
      }
      case FORGOT_PASSWORD_S: {
        draft.loading = false
        break
      }
      case FORGOT_PASSWORD_F: {
        draft.loading = false
        break
      }
      case RESET_PASSWORD_R: {
        draft.loading = true
        draft.validationsErrors = null
        break
      }
      case RESET_PASSWORD_S: {
        draft.loading = false
        draft.validationsErrors = null
        break
      }
      case RESET_PASSWORD_F: {
        draft.loading = false
        break
      }
      case RESET_PASSWORD_V: {
        draft.loading = false
        draft.validationsErrors = payload.errors
        break
      }
      case SIGN_OUT_R: {
        draft.token = null
        draft.signed = false
        draft.account_verify = false
        draft.validationsErrors = null
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}

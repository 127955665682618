import { api } from './_httpClient'

const profile = async () => api.get('/user/profile')
const update = async payload => api.put('/user', payload)
const changePassword = async payload =>
  api.post('/user/change_password', payload)
const sendVerifyAccount = async payload =>
  api.post('/user/send_verify_account', payload)
const verifyAccount = async payload => api.post('/user/verify_account', payload)
const certificate = async payload => api.post('/certificate', payload)

export {
  profile,
  update,
  changePassword,
  sendVerifyAccount,
  verifyAccount,
  certificate
}

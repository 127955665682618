import {
  GET_COURSE_SLUG_R,
  GET_COURSE_SLUG_S,
  GET_COURSE_SLUG_F,
  SET_CURRENT,
  CURRENT,
  NEXT_LESSON,
  HANDLE_PROGRESS_R,
  HANDLE_PROGRESS_S,
  HANDLE_PROGRESS_F
} from './types'

// Get course by slug
export const getCourseSlugRequest = payload => ({
  type: GET_COURSE_SLUG_R,
  payload
})
export const getCourseSlugSuccess = payload => ({
  type: GET_COURSE_SLUG_S,
  payload
})
export const getCourseSlugFailure = () => ({
  type: GET_COURSE_SLUG_F
})

// Set current lesson
export const setCurrent = payload => ({
  type: SET_CURRENT,
  payload
})
export const currentLesson = payload => ({
  type: CURRENT,
  payload
})
// on end load next Lesson
export const nextLesson = () => ({
  type: NEXT_LESSON
})
// handle progress on finish lesson
export const handleProgressR = payload => ({
  type: HANDLE_PROGRESS_R,
  payload
})
// handle progress on finish lesson
export const handleProgressS = payload => ({
  type: HANDLE_PROGRESS_S,
  payload
})
// handle progress on finish lesson
export const handleProgressF = () => ({
  type: HANDLE_PROGRESS_F
})

// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

// Api
import api from '../../../api'

// Types
import {
  SIGN_IN_R,
  SIGN_UP_R,
  SIGN_OUT_R,
  SEND_VERIFY_ACCOUNT_R,
  FORGOT_PASSWORD_R,
  VERIFY_ACCOUNT_R,
  RESET_PASSWORD_R
} from './types'

// Redux
import {
  signInSuccess,
  signInFailure,
  signInValidation,
  signInAccount,
  signUpSuccess,
  signUpFailure,
  signUpValidation,
  sendVerifyAccountSuccess,
  sendVerifyAccountFailure,
  verifyAccountSuccess,
  verifyAccountFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  resetPasswordValidation
} from './actions'
import { persistor } from '../../../store'

// Services
import { history } from '../../../services'

// Utils
import { error } from '../../../utils'

export function* signIn({ payload }) {
  try {
    const { email, password } = payload

    const res = yield call(api.auth.authenticate, { email, password })

    const {
      data: {
        data: userData,
        status: { code }
      }
    } = res

    if ([201, 200].includes(code)) {
      const { user, token } = userData

      if (!user.user_token.user_verified) {
        toast.info('Sua conta não está verificada, verifique o seu email!')
        return yield put(
          signInAccount({
            verified: !user.user_token.user_verified,
            user_id: user.id,
            email: user.email
          })
        )
      }

      return yield put(signInSuccess({ token }))
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: userData,
          status: { code }
        }
      } = err.response
      if ([400].includes(code)) {
        if (userData) {
          const validations = userData.map(e => e)
          toast.error('Erro ao se autenticar. Verifique seus dados', error)
          return yield put(signInValidation({ errors: validations }))
        }
        toast.error(`Erro ao se autenticar. Email/Senha Invalidos`, error)
        return yield put(signInFailure())
      }

      if ([500].includes(code)) {
        toast.error('Erro ao se autenticar. tente novamente mais tarde!', error)
        return yield put(signInFailure())
      }
    } else {
      toast.error('Erro ao se autenticar. tente novamente mais tarde!', error)
      return yield put(signInFailure())
    }
  }
}

export function* signUp({ payload }) {
  try {
    const {
      name,
      email,
      cpf,
      cep,
      password,
      address,
      city,
      state,
      country
    } = payload

    const res = yield call(api.auth.create, {
      name,
      email,
      cpf,
      cep,
      password,
      address,
      city,
      state,
      country
    })

    const {
      data: {
        data: userData,
        status: { code }
      }
    } = res

    if ([200, 201].includes(code)) {
      toast.success('Sua conta foi criada, verifique o seu email para confirmar a conta!')
      yield put(signUpSuccess({ user: userData.user }))
      return history.back()
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: userData,
          status: { code }
        }
      } = err.response
      if ([400].includes(code)) {
        if (userData) {
          const validations = userData.map(e => e)
          toast.error('Erro ao criar um Usuario. Verifique seus dados', error)
          return yield put(signUpValidation({ errors: validations }))
        }
        toast.error(
          `Erro ao criar um Usuario. Verifique os dados inseridos. `,
          error
        )
        return yield put(signUpFailure())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao criar um Usuario. tente novamente mais tarde!',
          error
        )
        return yield put(signUpFailure())
      }
    } else {
      toast.error(
        'Erro ao criar um Usuario. tente novamente mais tarde!',
        error
      )
      return yield put(signUpFailure())
    }
  }
}

export function* sendVerifyAccount({ payload }) {
  try {
    const { user_id, email } = payload

    const res = yield call(api.auth.sendVerifyAccount, {
      user_id,
      email
    })

    const {
      data: {
        status: { code }
      }
    } = res

    if ([200].includes(code)) {
      toast.success(
        'O email de ativação foi enviado com sucesso, verifique o seu email!',
        {
          autoClose: 4000
        }
      )
      return yield put(sendVerifyAccountSuccess())
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        toast.error(`Não foi possivel enviar o email. ${message}`, error)
        return yield put(sendVerifyAccountFailure())
      }

      if ([500].includes(code)) {
        toast.error('Não foi possivel enviar o email. Tente mais tarde!', error)
        return yield put(sendVerifyAccountFailure())
      }
    } else {
      toast.error('Não foi possivel enviar o email. Tente mais tarde!', error)
      return yield put(sendVerifyAccountFailure())
    }
  }
}

export function* verifyAccount({ payload }) {
  try {
    const { id, token_id, verify_token } = payload

    const res = yield call(api.auth.verifyAccount, {
      user_id: id,
      token_id,
      verify_token
    })

    const {
      data: {
        status: { code }
      }
    } = res
    if ([200].includes(code)) {
      return yield put(verifyAccountSuccess({ confirmed: true }))
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code) && message) {
        toast.error(`Não foi possivel verificar a conta. ${message}`, error)
        return yield put(verifyAccountFailure())
      }

      if ([500].includes(code)) {
        toast.error(
          'Não foi possivel verificar a conta. Tente mais tarde!',
          error
        )
        return yield put(verifyAccountFailure())
      }
    } else {
      toast.error(
        'Não foi possivel verificar a conta. Tente mais tarde!',
        error
      )
      return yield put(verifyAccountFailure())
    }
  }
}

export function* forgotPassword({ payload }) {
  try {
    const { email } = payload

    const res = yield call(api.auth.sendForgotPassword, {
      email
    })

    const {
      data: {
        status: { code }
      }
    } = res

    if ([200].includes(code)) {
      toast.success(
        'O email de recuperação foi enviado com sucesso, verifique o seu email!',
        {
          autoClose: 4000
        }
      )
      history.back()
      return yield put(forgotPasswordSuccess())
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        toast.error(`Não foi possivel enviar o email. ${message}`, error)
        return yield put(forgotPasswordFailure())
      }

      if ([500].includes(code)) {
        toast.error('Não foi possivel enviar o email. Tente mais tarde!', error)
        return yield put(forgotPasswordFailure())
      }
    } else {
      toast.error('Não foi possivel enviar o email. Tente mais tarde!', error)
      return yield put(forgotPasswordFailure())
    }
  }
}

export function* resetPassword({ payload }) {
  try {
    const { id, token_id, token, password } = payload

    const res = yield call(api.auth.resetPassword, {
      user_id: id,
      token_id,
      token,
      password
    })

    const {
      data: {
        status: { code }
      }
    } = res

    if ([200].includes(code)) {
      toast.success('Sua Senha foi trocada com sucesso!', {
        autoClose: 4000
      })
      history.back()
      return yield put(resetPasswordSuccess())
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: userData,
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        if (userData) {
          const validations = userData.map(e => e)
          toast.error(
            'Não foi possivel trocar a senha. Verifique seus dados',
            error
          )
          return yield put(resetPasswordValidation({ errors: validations }))
        }
        toast.error(`Não foi possivel trocar a senha. ${message}`, error)
        return yield put(resetPasswordFailure())
      }

      if ([500].includes(code)) {
        toast.error('Não foi possivel trocar a senha. Tente mais tarde!', error)
        return yield put(resetPasswordFailure())
      }
    } else {
      toast.error('Não foi possivel trocar a senha. Tente mais tarde!', error)
      return yield put(resetPasswordFailure())
    }
  }
}

export function signOut() {
  persistor.pause()
  persistor.flush().then(() => persistor.purge())
  history.go('/')
}

export default all([
  takeLatest(SIGN_IN_R, signIn),
  takeLatest(SIGN_UP_R, signUp),
  takeLatest(SEND_VERIFY_ACCOUNT_R, sendVerifyAccount),
  takeLatest(VERIFY_ACCOUNT_R, verifyAccount),
  takeLatest(FORGOT_PASSWORD_R, forgotPassword),
  takeLatest(RESET_PASSWORD_R, resetPassword),
  takeLatest(SIGN_OUT_R, signOut)
])

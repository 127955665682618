import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  :root {
    --color-error: #d34242;
    --color-background: #202024;
    --color-green: #0E9A52;
    --color-green-light: #0E9A53;
    --color-green-dark: #0E9A59;
    --color-gray: #373435;
    --color-gray-light: #e1e1e6;
    --color-gray-semi-dark: #a8a8b3;
    --color-gray-dark: #cccccc;
    --color-white: #fff;
  }
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    min-height: 100%;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    background-color: var(--color-background);
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizelegibility !important;
  }

  body, input, button {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-size: 54px;
    line-height: 64px;
    color: #e1e1e6;
    margin-bottom: 24px;
  }

  input, textarea {
    outline: 0px;
    background: #121214;
    border: 2px solid rgb(40, 39, 44);
    border-radius: 5px;
    height: 50px;
    padding: 15px 21px;
    color: rgb(255, 255, 255);
    font-family: Roboto, sans-serif;
    font-size: 17px;
    width: 100%;
    transition: border 0.2s ease 0s;

  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  .CustomLink {

    button {
      font-size: 16px;
      font-weight: 600;
      color: var(--color-green);
      margin: 34px 0px 24px;
      align-self: center;
      transition: opacity 0.2s ease 0s;
      border: none;
      background: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .Toastify__progress-bar--dark {
    background: var(--color-green);
  }
  .Toastify__toast-container--top-right {
    top: 8em;
  }
  .Toastify__toast--dark  {
    background: rgb(32, 32, 36);
  }

  @media print {
    html, body {
    height: 100vh;
    width: 100%;
    background-image: ${({banner}) =>
      banner
        ? `url(${banner})`
        : `url('https://wallpapercave.com/wp/wp1856658.jpg')`};
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    }
    @page { size: landscape; }

    .print-omit {
      display:none;
    }
    .print_middle {
      height: 100vh;
    }

    @media screen and (max-width: 425px) {
      .print_wrapper { width: 100%; }
    }
  }
`

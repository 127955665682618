// Sign in
export const SIGN_IN_R = '@auth/SIGN_IN_R'
export const SIGN_IN_S = '@auth/SIGN_IN_S'
export const SIGN_IN_F = '@auth/SIGN_IN_F'
export const SIGN_IN_V = '@auth/SIGN_IN_V'
export const SIGN_IN_A = '@auth/SIGN_IN_A'

// Sign up
export const SIGN_UP_R = '@auth/SIGN_UP_R'
export const SIGN_UP_S = '@auth/SIGN_UP_S'
export const SIGN_UP_F = '@auth/SIGN_UP_F'
export const SIGN_UP_V = '@auth/SIGN_UP_V'

// Account Verifycation
export const SEND_VERIFY_ACCOUNT_R = '@auth/SEND_VERIFY_ACCOUNT_R'
export const SEND_VERIFY_ACCOUNT_S = '@auth/SEND_VERIFY_ACCOUNT_S'
export const SEND_VERIFY_ACCOUNT_F = '@auth/SEND_VERIFY_ACCOUNT_F'
export const VERIFY_ACCOUNT_R = '@auth/VERIFY_ACCOUNT_R'
export const VERIFY_ACCOUNT_S = '@auth/VERIFY_ACCOUNT_S'
export const VERIFY_ACCOUNT_F = '@auth/VERIFY_ACCOUNT_F'

// Forgot Password
export const FORGOT_PASSWORD_R = '@auth/FORGOT_PASSWORD_R'
export const FORGOT_PASSWORD_S = '@auth/FORGOT_PASSWORD_S'
export const FORGOT_PASSWORD_F = '@auth/FORGOT_PASSWORD_F'
export const RESET_PASSWORD_R = '@auth/RESET_PASSWORD_R'
export const RESET_PASSWORD_S = '@auth/RESET_PASSWORD_S'
export const RESET_PASSWORD_F = '@auth/RESET_PASSWORD_F'
export const RESET_PASSWORD_V = '@auth/RESET_PASSWORD_V'

// Sign Out
export const SIGN_OUT_R = '@auth/SIGN_OUT_R'

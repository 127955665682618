// Libs
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import { LoadingCourses } from '../../components'

// Redux
import { getCoursesRequest } from '../../store/modules'

// Styles
import { Container, Content, Courses, CourseLink } from './styles'

export const Dashboard = () => {
  // Redux
  const dispatch = useDispatch()
  const { profile } = useSelector(state => state.user)
  const { courses, loading } = useSelector(state => state.courses)

  useEffect(() => {
    if (!courses) dispatch(getCoursesRequest())
  }, [courses, dispatch])

  return (
    <Container>
      <Content>
        <section>
          <h2>Conteúdo Disponível</h2>
          <Courses>
            {loading ? (
              <>
                <LoadingCourses />
                <LoadingCourses />
                <LoadingCourses />
                <LoadingCourses />
              </>
            ) : profile && courses ? (
              courses.map((e, i) =>
                e.is_active ? (
                  <CourseLink key={i} to={`/course/${e.slug}`}>
                    <img src={e.thumbnail} alt={e.title} />
                    <span>{e.title}</span>
                    <p>{e.description}</p>
                  </CourseLink>
                ) : null
              )
            ) : null}
          </Courses>
        </section>
      </Content>
    </Container>
  )
}

import React from 'react'

import { Container } from './styles'
import { Spinner } from '../Spinner'

export const Button = ({ type, loading, children, ...rest }) => {
  return (
    <Container type={type} {...rest}>
      {loading ? <Spinner /> : children}
    </Container>
  )
}

import { produce } from 'immer'

import {
  GET_COURSE_SLUG_R,
  GET_COURSE_SLUG_S,
  GET_COURSE_SLUG_F,
  HANDLE_PROGRESS_S,
  SET_CURRENT
} from './types'

const initialState = {
  course: {},
  current: {},
  lessons: {},
  progress: 0,
  loading: false
}

export const player = (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case SET_CURRENT: {
        draft.current = payload.nextLesson
        break
      }
      case GET_COURSE_SLUG_R: {
        draft.loading = true
        break
      }
      case HANDLE_PROGRESS_S: {
        draft.progress = payload.progress
        draft.lessons = payload.lessons
        break
      }
      case GET_COURSE_SLUG_S: {
        draft.loading = false
        draft.course = payload.course
        draft.lessons = payload.course.group.lessons
        draft.current = payload.course.current_lesson
        draft.progress = payload.progress
        break
      }
      case GET_COURSE_SLUG_F: {
        draft.loading = false
        break
      }
      default:
        return state
    }
  })
}

import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import createStore from './createStore'

import rootReducer from './modules/rootReducer'
import rootSaga from './modules/rootSaga'

import persistReducers from './persistReducers'

// Setting the middlewares
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

// Creating the store
const store = createStore(persistReducers(rootReducer), middlewares)
const persistor = persistStore(store)
// Running sagas
sagaMiddleware.run(rootSaga)

export { store, persistor }

import styled from 'styled-components'

export default styled.div`
  background-image: linear-gradient(
    -90deg,
    #414446 0%,
    #3e3c3c 50%,
    #414446 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

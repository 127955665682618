// Libs
import React from 'react'
import { Switch } from 'react-router-dom'

// Routes
import { RouteWrapper } from './Route'

// Pages
import {
  SignIn,
  SignUp,
  Forgot,
  Dashboard,
  AccountVerify,
  ResetPassword,
  Courses,
  Profile,
  History,
  Certificate
} from '../pages'

export const Routes = () => {
  return (
    <Switch>
      {/* Auth Route */}
      <RouteWrapper path="/" exact component={SignIn} />
      <RouteWrapper path="/signup" exact component={SignUp} />
      <RouteWrapper path="/forgot" exact component={Forgot} />
      <RouteWrapper
        path="/reset_password/:id/:token_id/:token"
        component={ResetPassword}
      />

      {/* Dashboard Route */}
      <RouteWrapper path="/dashboard" exact component={Dashboard} isPrivate />
      <RouteWrapper
        path="/verify/:id/:token_id/:verify_token"
        component={AccountVerify}
      />
      <RouteWrapper
        path="/course/:slug/:lesson?"
        component={Courses}
        isPrivate
      />
      <RouteWrapper path="/profile" exact component={Profile} isPrivate />
      <RouteWrapper path="/history" exact component={History} isPrivate />
      <RouteWrapper
        path="/certificate/:course_id"
        exact
        component={Certificate}
        isPrivate
      />
    </Switch>
  )
}

// Libs
import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { MdFolder, MdLink } from 'react-icons/md'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'

// Redux
import {
  nextLesson,
  handleProgressR,
  currentLesson
} from '../../../store/modules'

import { history } from '../../../services'

// Subcomponents
import { Player } from '../Video'

// Styles
import {
  Container,
  Content,
  Wrapper,
  PlayerContent,
  Side,
  SideContent,
  SideWrapper,
  Attachments,
  Dropdown,
  DropdownContent,
  DropdownWrapper,
  LessonList,
  LessonContent,
  Lesson
} from './styles'

export const Group = () => {
  // Hooks
  const { lesson: lessonSlug } = useParams()
  const player = useRef()

  // Redux
  const dispatch = useDispatch()
  const { course, current, progress, lessons } = useSelector(
    state => state.player
  )

  useEffect(() => {
    if (lessonSlug) {
      dispatch(currentLesson({ course, current: lessonSlug }))
    }
  }, [course, lessonSlug, dispatch])

  const handleTimeUpdate = useCallback(time => { }, [])

  const handleCertificated = useCallback(() => history.replace(`/certificate/${course.id}`), [course.id])

  const handleEnd = useCallback(() => {
    if (player.current) {
      player.current.player.isPlaying = false
      const duration = player.current.getDuration()

      const percentage = Math.floor(
        (player.current.getCurrentTime() * 100) / duration
      )
      if (percentage > 98) {
        dispatch(handleProgressR({ time: duration, percentage }))
        dispatch(nextLesson())
      }
    }
  }, [dispatch])

  const attachmentsLessons = useMemo(() => {
    if (course && course.group.lessons) {
      const materialLesson = course.group.lessons.filter(
        lesson => lesson.content.slug === current.content.slug
      )
      if (materialLesson.length > 0 && materialLesson[0].content.attachments) {
        return (
          <>
            <p>Anexos:</p>
            {materialLesson &&
              materialLesson[0].content.attachments
                .split(',')
                .map((attach, index) => (
                  <a
                    key={index}
                    href={attach}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MdLink size={24} /> Anexo {index}
                  </a>
                ))}
          </>
        )
      }
    }
  }, [current, course])

  const PlayerMemo = useMemo(() => {
    if (course && current && lessons) {
      const lastElement = lessons.filter(lesson => lesson.type !== 'material')
      const lastElementBy = lastElement.length > 1 ? lastElement[lastElement.length - 1] : lastElement[0]

      return (
        <Wrapper>
          <Side>
            <SideContent>
              <p>Progresso:</p>
              <SideWrapper>
                {
                  <CircularProgressbarWithChildren
                    className="circular-progress"
                    value={progress}
                  >
                    <p>{progress}%</p>
                  </CircularProgressbarWithChildren>
                }
                <Attachments>{attachmentsLessons}</Attachments>
              </SideWrapper>
              {Number(progress) >= 95 && (
                <a href={`/certificate/${course.id}`} onClick={handleCertificated} >Certificado</a>
              )}
            </SideContent>
          </Side>
          <PlayerContent>
            {current.type !== 'material' ? (
                <Player
                  ref={player}
                  player={current.content.platform}
                  provider={current.content.resource}
                  onTimeUpdate={handleTimeUpdate}
                  onEnd={handleEnd}
                  controls={true}
                />
            ) : null}
            <h1>{current.content.title}</h1>
            <p>{course.description}</p>
          </PlayerContent>
          <Dropdown>
            <DropdownContent>
              <DropdownWrapper>
                <LessonContent>
                  <LessonList>
                    {lessons.map((lesson, index, array) => (
                      <Lesson
                        key={lesson.id}
                        watched={
                          lesson.content.progress[0] &&
                          lesson.content.progress[0].completed
                        }
                        active={lesson.content.slug === current.content.slug}
                        type={lesson.type}
                        hideConnector={
                          array[index + 1] &&
                          array[index + 1].type !== 'video' &&
                          array[index + 1].type !== 'sound'
                        }
                        className={lastElementBy && lastElementBy.id === lesson.id ? 'lastElement' : ''}
                      >
                        {lesson.type !== 'video' && lesson.type !== 'sound' ? (
                          <>
                            <MdFolder />
                            <a href={lesson.content.resource}>
                              {lesson.content.title}
                            </a>
                          </>
                        ) : (
                          <>
                            <button type="submit" />
                            <Link
                              to={`/course/${course.slug}/${lesson.content.slug}`}
                            >
                              {lesson.content.title}
                            </Link>
                          </>
                        )}
                      </Lesson>
                    ))}
                  </LessonList>
                </LessonContent>
              </DropdownWrapper>
            </DropdownContent>
          </Dropdown>
        </Wrapper >
      )
    }
  }, [
    attachmentsLessons,
    course,
    current,
    handleEnd,
    handleTimeUpdate,
    handleCertificated,
    lessons,
    progress
  ])
  return (
    <Container>
      <Content>{PlayerMemo}</Content>
    </Container>
  )
}

// Libs
import React, { useRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from '@unform/web'
import { MdLoop } from 'react-icons/md'

// Components
import { Input, Button } from '../../components'

// Redux
import {
  updateUserRequest,
  getCepRequest
} from '../../store/modules'

// Validations
import { userSchema } from '../../validation'

// Styles
import { Container, Content, Tables } from './styles'

// Responsive
import { Row, Column } from '../../styles'

export const Profile = () => {
  // Hooks
  const formRef = useRef(null)

  // Redux
  const dispatch = useDispatch()
  const { profile, loading, validationsErrors } = useSelector(
    state => state.user
  )
  const { cep } = useSelector(state => state.utils)

  const setCep = useCallback(
    value => {
      value.length >= 8 && dispatch(getCepRequest({ cep: value }))
    },
    [dispatch]
  )

  // Submit form Request
  const handleSubmit = useCallback(
    async submitData => {
      try {
        formRef.current.setErrors({})

        await userSchema.update.validate(submitData, {
          abortEarly: false
        })

        const {
          name,
          email,
          cpf: cleanCpf,
          cep,
          city,
          state,
          address,
          country
        } = submitData
        const cpf = cleanCpf.replace('-', '').replace('.', '').replace('.', '')
        dispatch(
          updateUserRequest({
            id: profile.id,
            name,
            email,
            cpf,
            cep,
            address,
            city,
            state,
            country
          })
        )
      } catch (err) {
        const validationErrors = {}
        if (err) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    },
    [dispatch, profile]
  )

  const FormMemo = useMemo(() => {
    if (profile) {
      return (
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: profile.name,
            email: profile.email,
            cpf: profile.cpf,
            cep: profile.cep,
            address: profile.address,
            city: profile.city,
            state: profile.state,
            country: profile.country
          }}
        >
          <Row>
            <Column xs="12" sm="5" md="5" lg="6">
              <Input
                name="name"
                type="text"
                label="Nome"
                placeholder="Seu Nome..."
              />
            </Column>
            <Column xs="12" sm="5" md="5" lg="6">
              <Input
                name="email"
                type="text"
                label="Email"
                placeholder="Seu Email..."
              />
            </Column>
            <Column xs="12" sm="5" md="5" lg="6">
              <Input
                name="cpf"
                type="text"
                label="Cpf"
                placeholder="Seu Cpf..."
                unform={false}
                mask="999.999.999-99"
              />
            </Column>
            <Column xs="12" sm="5" md="5" lg="6">
              <Input
                name="cep"
                type="text"
                aria-label="cep"
                placeholder="Seu Cep..."
                label="Cep"
                icon="FaGlobeAmericas"
                autoComplete="off"
                onChange={e => setCep(e.target.value)}
              />
            </Column>
            <Column xs="12" sm="5" md="5" lg="6">
              <Input
                name="address"
                type="text"
                label="Endereço"
                placeholder="Seu Endereço..."
              />
            </Column>
            <Column xs="12" sm="5" md="5" lg="6">
              <Input
                name="city"
                type="text"
                label="Cidade"
                placeholder="Sua Cidade..."
              />
            </Column>
            <Column xs="12" sm="5" md="5" lg="6">
              <Input
                name="state"
                type="text"
                label="Estado"
                placeholder="Seu Estado..."
              />
            </Column>
            <Column xs="12" sm="5" md="5" lg="6">
              <Input
                name="country"
                type="text"
                label="País"
                placeholder="Seu País..."
              />
            </Column>
            <Column xs="12" sm="5" md="5" lg="6" className="padding-top validations">
              {validationsErrors &&
                validationsErrors.map((e, i) => <p key={i}>{e}</p>)}
            </Column>
            <Column xs="12" sm="5" md="5" lg="12">
              <Button loading={loading} type="submit">
                <MdLoop size={24} /> Atualizar
              </Button>
            </Column>
          </Row>
        </Form>
      )
    }
  }, [profile, loading, validationsErrors, handleSubmit, setCep])

  useEffect(() => {
    const setInfos = () => {
      if (cep) {
        formRef.current.setData({
          address: cep.logradouro,
          city: cep.localidade,
          state: cep.uf,
          country: 'Brasil'
        })
      }
    }
    return setInfos()
  }, [cep])

  // Profile
  return (
    <Container>
      <Content>
        <section>
          <div>
            <h2>Meu Dados</h2>
          </div>
          {FormMemo}
        </section>
        <section>
          <div>
            <h2>Certificados</h2>
          </div>
          {profile
            ? profile.certificates.length > 0 && (
              <Tables>
                <table>
                  <thead>
                    <tr>
                      <th>Curso</th>
                      <th>Total de Aulas</th>
                      <th>Total de Horas/Minutos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profile.certificates.map(e => (
                      <tr key={e.id}>
                        <td>
                          <div>{e.name}</div>
                        </td>
                        <td>
                          <div>{e.lessons}</div>
                        </td>
                        <td>
                          <div>
                            {Number(e.hours / 60) < 60
                              ? `${Number(e.hours / 60).toFixed(0)} Minutos`
                              : `${Number(e.hours / 60 / 60).toFixed(
                                0
                              )} Horas`}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Tables>
            )
            : null}
        </section>
      </Content>
    </Container>
  )
}

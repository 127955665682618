import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  justify-content: center;

  background: #12121496;
  padding-top: 72px;
  min-height: 100vh;
`

export const Content = styled.div`
  width: 100%;
  max-width: 1180px;
  padding: 50px 30px;

  section {
    h2 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 1em;
      color: rgb(225, 225, 230);
    }
  }

  section + section {
    margin-top: 48px;
    padding-top: 48px;
    border-top: 1px solid rgb(41, 41, 46);
  }
`

export const Courses = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
`

export const CourseLink = styled(Link)`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 36px 24px;
  background: rgb(32, 32, 36);
  border-radius: 5px 5px 0px 0px;
  transition: background 0.2s ease 0s;

  :hover {
    background: rgb(41, 41, 46);
  }

  img {
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
  }

  span {
    font-size: 24px;
    font-weight: bold;
    margin-top: 1em;
    color: rgb(225, 225, 230);
    white-space: nowrap;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    font-size: 16px;
    line-height: 2;
    color: rgb(168, 168, 179);
    text-align: center;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 320px) {
    span { 
      max-width: 180px;
    }
    p { 
      max-width: 180px;
    }
  }

  @media screen and (max-width: 375px) {
    span { 
      max-width: 200px;
    }
    p { 
      max-width:  200px;
    }
  }
  @media screen and (max-width: 768px) {
    span { 
    max-width: 250px;
    }
  }
`

export const ComingSoon = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
`

export const Card = styled.div`
  padding: 36px 32px;
  background: rgb(32, 32, 36);
  border-radius: 5px;
  cursor: not-allowed;

  h3 {
    display: flex;
    align-items: center;
    font-size: 24px;

    svg {
      flex-shrink: 0;
      width: 2em;
      margin-right: 0.5em;
    }

    span {
      color: rgb(225, 225, 230);
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      color: rgb(168, 168, 179);
    }
  }
`

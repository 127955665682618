// Libs
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

// Redux
import { bannerListR } from '../../../store/modules'

// Styles
import { Container, Wrapper, Content } from './styles'

export const AuthLayout = ({ children }) => {
  // Redux
  const dispatch = useDispatch()
  const { banners: { authenticate } } = useSelector(state => state.banners)

  useEffect(() => {
    if (!authenticate) dispatch(bannerListR())
  }, [authenticate, dispatch])

  return (
    <Container banner={authenticate && authenticate}>
      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
    </Container>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired
}

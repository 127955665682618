// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

// Api
import api from '../../../api'

// Types
import {
  GET_PROFILE_R,
  UPDATE_USER_R,
  CHANGE_PASSWORD_R,
  CERTIFICATE_R
} from './types'

// Actions
import {
  getProfileSuccess,
  getProfileFailure,
  updateUserSuccess,
  updateUserFailure,
  updateUserValidation,
  changePasswordSuccess,
  changePasswordFailure,
  changePasswordValidation,
  certificateSuccess,
  certificateValidation,
  certificateFailure
} from './actions'

// Sagas
import { signIn } from '../auth/sagas'

// Utils
import { error } from '../../../utils'

export function* getProfile() {
  try {
    const res = yield call(api.user.profile)

    const {
      data: {
        data: userData,
        status: { code }
      }
    } = res

    if ([200].includes(code))
      return yield put(
        getProfileSuccess({
          profile: userData.user,
          userVerified: userData.user.user_token.user_verified
        })
      )
  } catch (err) {
    if (err.response) {
      const {
        data: {
          status: { code }
        }
      } = err.response
      if ([400].includes(code)) {
        toast.error(`Erro ao pegar o Usuario.`, error)
        return yield put(getProfileFailure())
      }

      if ([500].includes(code)) {
        toast.error('Erro ao pegar o Usuario. Atualize a pagina', error)
        return yield put(getProfileFailure())
      }
    } else {
      toast.error('Erro ao pegar o Usuario. Atualize a pagina', error)
      return yield put(getProfileFailure())
    }
  }
}

export function* updateUser({ payload }) {
  try {
    const { id, name, email, cpf, cep, address, city, state, country } = payload
    const res = yield call(api.user.update, {
      id,
      name,
      email,
      cpf,
      cep,
      address,
      city,
      state,
      country
    })

    const {
      data: {
        data: userData,
        status: { code }
      }
    } = res
    if ([200].includes(code)) {
      toast.success(`${userData.name} seus dados foram atualizado com Sucesso!`)
      yield put(updateUserSuccess())
      return yield call(getProfile)
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: userData,
          status: { code }
        }
      } = err.response
      if ([400].includes(code)) {
        if (userData) {
          const validations = userData.map(e => e)
          toast.error(
            'Erro ao atualizar o usuario. Verifique seus dados',
            error
          )
          return yield put(updateUserValidation({ errors: validations }))
        }
        toast.error(`Erro ao atualizar um Usuario. Verifique os dados`, error)
        return yield put(updateUserFailure())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao atualizar um Usuario. tente novamente mais tarde!',
          error
        )
        return yield put(updateUserFailure())
      }
    } else {
      toast.error(
        'Erro ao atualizar um Usuario. tente novamente mais tarde!',
        error
      )
      return yield put(updateUserFailure())
    }
  }
}

export function* changePassword({ payload }) {
  try {
    const { id, oldPassword, password, confirmPassword } = payload
    const res = yield call(api.user.changePassword, {
      id,
      oldPassword,
      password,
      confirmPassword
    })
    const {
      data: {
        data: userData,
        status: { code }
      }
    } = res
    if ([200].includes(code)) {
      toast.success(`${userData.name} sua senha foi alterada com sucesso!`)
      yield put(changePasswordSuccess())
      return yield call(signIn(userData.email, password))
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: userData,
          status: { code, message }
        }
      } = err.response
      if ([400].includes(code)) {
        if (userData) {
          const validations = userData.map(e => e)
          toast.error('Erro ao atualizar a senha. Verifique seus dados', error)
          return yield put(changePasswordValidation({ errors: validations }))
        }
        toast.error(`Erro ao atualizar a senha. ${message}`, error)
        return yield put(changePasswordFailure())
      }

      if ([500].includes(code)) {
        toast.error(
          'Erro ao atualizar a senha. tente novamente mais tarde!',
          error
        )
        return yield put(changePasswordFailure())
      }
    }
  }
}

export function* generateCertificate({ payload }) {
  try {
    const { course_id } = payload
    const res = yield call(api.user.certificate, {
      course_id
    })
    const {
      data: {
        data: certificateData,
        status: { code }
      }
    } = res
    if ([200, 201].includes(code)) {
      yield put(
        certificateSuccess({
          certificate: certificateData
        })
      )
    }
  } catch (err) {
    if (err.response) {
      const {
        data: {
          data: certificateData,
          status: { code }
        }
      } = err.response
      if ([400].includes(code)) {
        if (certificateData) {
          const validations = certificateData.map(e => e)
          return yield put(certificateValidation({ errors: validations }))
        }
        return yield put(certificateFailure())
      }

      if ([500].includes(code)) {
        return yield put(certificateFailure())
      }
    }
  }
}

export default all([
  takeLatest(GET_PROFILE_R, getProfile),
  takeLatest(UPDATE_USER_R, updateUser),
  takeLatest(CHANGE_PASSWORD_R, changePassword),
  takeLatest(CERTIFICATE_R, generateCertificate)
])

// Libs
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import axios from 'axios'

// Types
import { GET_CEP_R } from './types'

// Redux
import { getCepSuccess, getCepFailure } from './actions'

// Utils
import { error } from '../../../utils'

// Api
const viaCep = async cep =>
  await axios.get(`https://viacep.com.br/ws/${cep}/json/`)

export function* getCep({ payload }) {
  try {
    const { cep } = payload
    const { data, status } = yield call(viaCep, cep)
    if ([200].includes(status)) {
      if (data.erro) {
        toast.error('Não foi possivel encontrar o cep. Tente novamente!', error)
        return yield put(getCepFailure({ error: data.erro }))
      } else {
        toast.success('Cep encontrado!')
        return yield put(getCepSuccess({ cep: data}))
      }
    }
  } catch (err) {}
}

export default all([takeLatest(GET_CEP_R, getCep)])
